import "./AdminTransactions.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import ProductService from '../Service/ProductService';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { useHistory } from "react-router-dom";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import TransactionService from '../Service/TransactionService';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUploadService } from '../Service/FileUploadService';
import TransactionReports from './TransactionReports'
import { useAppContext } from "../libs/contextLib";

const AdminTransactions = () => {
    const productService = new ProductService();

    const history = useHistory();
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isTransactionItemsLoading, setTransactionItemsIsLoading] = useState(false);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [isSingleTransaction, setIsSingleTransaction] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const transactionsLoading = new Array(3);
    const fileUploadService = new FileUploadService();
    const transactionService = new TransactionService();
    const [transactionItems, setTransactionItems] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
    const [selectedTransactionTotal, setSelectedTransactionTotal] = useState(0);
    const [selectedTransactionID, setSelectedTransactionID] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [periods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [months,] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
    const [config, setConfig] = useState(null);
    const { userHasAuthenticated, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor} = useAppContext();

    const treeTableFuncMap = {
        'globalFilter2': setGlobalFilter2
    };

    const getHeader = (globalFilterKey) => {
        return (
            <div className="p-text-right">
                <h5 className="p-text-left" style={{ marginBottom: '-30px' }}>Transactions</h5>
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Search" size="50" />
                </div>
            </div>
        );
    }
    let header2 = getHeader('globalFilter2');

    useEffect(() => {
        setIsLoading(true);
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user == null)
            return;

        if (user == null || user.userType !== '3900') {
            history.push("/NotFound");
            return;
        }

        //Get Current Year 
        var date = new Date();
        var currentYear = date.getFullYear();
        setSelectedPeriod(currentYear);
        setCurrentPeriod(currentYear);
        setLoggedInUser(user);

        let _config = {headers: { Authorization: `Bearer ${user.token}` }};
        setConfig({
            ..._config
        });

        //Get Periods company 
        transactionService.getAllPeriods(_config).then(data => {
            setPeriods(data);
            console.log("Periods", data);
        }) 

        transactionService.getTreeTableTransactions(_config, currentYear).then(data => {
            setIsLoading(false);
            setTransactions(data);
        }).catch(function (error) {
            console.log(error.response);

            if(error?.response?.status === 401)
            {
               userHasAuthenticated(false);
               //Clear Local storage
               userIsCompanyAdmin(false);
               userIsCompanyCashier(false);
               userIsCompanySupervisor(false);
               window.localStorage.clear();
           
               //Redirect to login
                history.push("/login");
            } 
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const headerTabList = (
        <span >
            <i className="pi pi-list" style={{ marginRight: "10px" }} />
            <label style={{ cursor: "pointer" }}>Transaction List </label>
        </span>
    );

    const headerTabSettings = (
        <span>
            <i className="pi pi-cog" style={{ marginRight: "10px" }} />
            <label>Transaction Settings</label>
        </span>
    );

    const headerTabReports = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-chart-line" style={{ marginRight: "10px" }} />
            <label>Transaction Reports</label>
        </span>
    );

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }

    const onHide = () => {
        setIsSingleTransaction(false);
    }
    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
            </div>
        );
    }

    const dialogHeader = () => {
        return selectedTransactionID;
    }


    const imageBodyTemplate = (rowData) => {
        return <img height="50px" width="50px" src={rowData.itemImage || fileUploadService.processImageLocation(null)} alt={rowData.itemImage} />
    }

    const priceExcVATBodyTemplate = (rowData) => {
        return transactionService.formatCurrency(rowData.priceExcVAT);
    }

    const totalVATBodyTemplate = (rowData) => {
        return transactionService.formatCurrency(rowData.totalVAT);
    }

    const totalPriceIncVATBodyTemplate = (rowData) => {
        return transactionService.formatCurrency(rowData.totalPriceIncVAT);
    }

    const onSelect = (event) => {

        let key = event.node.Key;
        // var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

        if (!months.includes(key)) {
            setIsSingleTransaction(true);
            loadTransactionItems(key)
        }
    }

    const loadTransactionItems = (transactionId) => {
        setTransactionItemsIsLoading(true);

        transactionService.getTransactionByID(config, transactionId).then(data => {
            setTransactionItemsIsLoading(false);
            setTransactionItems(data.transactionItems);
            setSelectedTransactionTotal(data.totalTransactionPrice);
            setSelectedTransactionID(data.transactionId);
        }).catch(function (error) {
            console.log(error.response);
        });

    }

    const actionTemplate = (node, column) => {
        let amount = parseFloat(node.data.AmountRefunded.replace('R', ''));
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{node.data.AmountRefunded}</p> : <p> {node.data.AmountRefunded}</p>}
        </div>;
    }

    const transactionMonthActionTemplate = (node, column) => {
        let isHeader = months.includes(node.data.TransactionMonth);

        return (isHeader ? <b>{node.data.TransactionMonth}</b> : node.data.TransactionMonth);
    }

    const totalTransactionPriceTemplate = (node, column) => {
        let amount = parseFloat(node.data.TotalTransactionPrice.replace('R', ''));
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{node.data.TotalTransactionPrice}</p> : <p> {node.data.TotalTransactionPrice}</p>}
        </div>;
    }

    const totalVATTemplate = (node, column) => {
        let amount = parseFloat(node.data.TotalVAT.replace('R', ''));
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{node.data.TotalVAT}</p> : <p> {node.data.TotalVAT}</p>}
        </div>;
    }

    function onSelectTab(event) {
        setAccordionActiveIndex(event.index);
        if (event != undefined || event != null) {
            setIsLoading(true);
            setSelectedPeriod(periods[event.index])
            transactionService.getTreeTableTransactions(config, periods[event.index]).then(data => {
                setIsLoading(false);
                setTransactions(data);
            }).catch(function (error) {
                console.log(error.response);
            });
        }
    }
    
    const dynamicAccordionTabs = periods.map((value) => {

        let headerValue = currentPeriod === value ? value + " - Current Year" : value;

        if (value === selectedPeriod)
            return <AccordionTab header={headerValue} key={value}>
                <div className="card">
                    {
                        !isLoading ?
                            <TreeTable dataKey={value} value={transactions} globalFilter={globalFilter2} onSelect={onSelect} selectionMode="single" selectionKeys={selectedTransaction} onSelectionChange={e => setSelectedTransaction(e.value)} reorderableColumns header={header2}>
                                <Column field="TransactionMonth" header="TransactionID" body={transactionMonthActionTemplate} expander sortable></Column>
                                <Column field="Date" header="Transaction Date" sortable></Column>
                                <Column field="TransactionType" header="Transaction Type" sortable></Column>
                                <Column field="TotalTransactionPrice" header="Transaction Amount" body={totalTransactionPriceTemplate} sortable></Column>
                                <Column field="TotalVAT" header="Transaction VAT" body={totalVATTemplate} sortable></Column>
                                <Column field="NumberOfItems" header="Quantity Movement" sortable></Column>
                                <Column field="PaymentMehod" header="Payment Method" sortable></Column>
                                <Column field="AmountReceived" header="Amount Received" sortable></Column>
                                <Column field="ChangedReturned" header="Change Rendered" sortable></Column>
                                <Column field="AmountRefunded" header="Amount Refunded" body={actionTemplate} sortable></Column>
                            </TreeTable>
                            :
                            <DataTable value={transactionsLoading} globalFilter={globalFilter2} header={header2}>
                                <Column field="TransactionMonth" header="Month" body={bodyTemplate}></Column>
                                <Column field="transactionDate" header="Transaction Date" body={bodyTemplate}></Column>
                                <Column field="transactionId" header="Transaction ID" body={bodyTemplate}></Column>
                                <Column field="transactionAmount" header="Transaction Amount" body={bodyTemplate}></Column>
                                <Column field="transactionVAT" header="Transaction VAT" body={bodyTemplate}></Column>
                                <Column field="transactionQuantity" header="Quantity Movement" body={bodyTemplate}></Column>
                                <Column field="paymentMethod" header="Payment Method" body={bodyTemplate}></Column>
                                <Column field="amountReceived" header="Amount Received" body={bodyTemplate}></Column>
                                <Column field="changeRendered" header="Change Rendered" body={bodyTemplate}></Column>
                                <Column field="amountRefunded" header="Amount Refunded" body={bodyTemplate}></Column>
                            </DataTable>
                    }
                </div>
            </AccordionTab>
        else
            return <AccordionTab header={headerValue} key={value}>

            </AccordionTab>
    });

    return (
        <div>
            <div className="tabview-demo">
                <div className="card">
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={headerTabList}>
                            {
                                periods.length ?
                                    <Accordion activeIndex={accordionActiveIndex} onTabOpen={(e) => onSelectTab(e)}>
                                        {dynamicAccordionTabs}
                                    </Accordion>
                                    :
                                    <DataTable value={transactionsLoading}>
                                        <Column header="Transactions" body={bodyTemplate}></Column>
                                    </DataTable>
                            }
                        </TabPanel>

                        <TabPanel header={headerTabSettings}> {/* Transaction Settings */}
                            <h1>Add stuff</h1>
                        </TabPanel>

                        <TabPanel header={headerTabReports}> {/* Transaction Reports*/}
                            <TransactionReports />

                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <Dialog header={dialogHeader} visible={isSingleTransaction} style={{ width: '60vw' }} footer={renderFooter()} onHide={() => onHide()}>

                {
                    !isTransactionItemsLoading
                        ?
                        <div>
                            <DataTable className="p-datatable-striped" value={transactionItems} header={"Transaction Items"} dataKey="id">
                                <Column headerStyle={{ width: '3rem' }}></Column>
                                <Column field="productBarCode" header="Code" ></Column>
                                <Column field="itemName" header="Name" ></Column>
                                <Column header="Image" body={imageBodyTemplate}></Column>
                                <Column field="priceExcVAT" header="Price Exc VAT" body={priceExcVATBodyTemplate}></Column>
                                <Column field="totalVAT" header="VAT" body={totalVATBodyTemplate}></Column>
                                <Column field="quantitySold" header="Quantity"></Column>
                                <Column field="totalPriceIncVAT" body={totalPriceIncVATBodyTemplate} header={"Total"}></Column>
                            </DataTable>

                            <DataTable value={[]}>
                                <Column header={<h3>Total</h3>}></Column>
                                <Column></Column>
                                <Column></Column>
                                <Column></Column>
                                <Column></Column>
                                <Column></Column>
                                <Column></Column>
                                <Column style={{ marginLeft: '10px' }} header={<h5>{transactionService.formatCurrency(selectedTransactionTotal)}</h5>}></Column>
                            </DataTable>
                        </div>
                        :
                        <DataTable className="p-datatable-striped" value={transactionsLoading} header={"Transactions "}>
                            <Column headerStyle={{ width: '3rem' }}></Column>
                            <Column body={bodyTemplate} header="Code" ></Column>
                            <Column body={bodyTemplate} header="Name" ></Column>
                            <Column body={bodyTemplate} header="Image"></Column>
                            <Column body={bodyTemplate} header="Price Exc VAT" ></Column>
                            <Column body={bodyTemplate} header={"VAT"}></Column>
                            <Column body={bodyTemplate} header="Quantity"></Column>
                            <Column body={bodyTemplate} header={<h4>Total</h4>}></Column>
                        </DataTable>
                }
            </Dialog>

        </div>
    );
}
export default AdminTransactions