import "./SingleSupplier.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { useHistory } from "react-router-dom";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUploadService } from '../Service/FileUploadService';
import { useAppContext } from "../libs/contextLib";
import { FileUpload } from 'primereact/fileupload';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import SupplierService from "../Service/SupplierService";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import SupplierOrders from './SupplierOrders';
import { AutoComplete } from 'primereact/autocomplete';
import API_URL from "../Service/ApiUrl";

const SingleSupplier = () => {

    const history = useHistory();
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveSettingLoading, setIsSaveSettingLoading] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [isInvoiceSaving, setIsInvoiceSaving] = useState(false);
    const [currentInvoices, setCurrentInvoices] = useState(null);
    const [originalInvoices, setOriginalInvoices] = useState();
    const [shouldSaveInvoice, setShouldSaveInvoice] = useState(false);
    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
    const [isInvoiceSettingsInfo, setIsInvoiceSettingsInfo] = useState(false);
    const [openedInvoice, setOpenedInvoice] = useState(null);
    const [globalFilter2, setGlobalFilter2] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const invoiceLoading = new Array(3);
    const fileUploadService = new FileUploadService();
    const supplierService = new SupplierService();
    const [activeIndex, setActiveIndex] = useState(2);
    const [accordionActiveIndex, setsetAccordionActiveIndex] = useState(0);
    const [periods, setPeriods] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [months,] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);
    const [config, setConfig] = useState(null);
    const { userHasAuthenticated, userIsCompanyAdmin, userIsCompanyCashier, userIsCompanySupervisor } = useAppContext();
    const toast = useRef(null);
    const apiBaseUrl = API_URL;
    const { supplierId } = useParams();
    const [currentSupplierId, SetCurrentSupplierId] = useState(null);
    const [fileUploadErrorMessage, SetFileUploadErrorMessage] = useState(null);
    const [DateFormat, setDateFomat] = useState(null);

    const [dateField, SetDateField] = useState('');
    const [datePosition, SetDatePosition] = useState('');
    const [numberField, SetNumberField] = useState('');
    const [numberPosition, SetNumberPosition] = useState('');
    const [totalField, SetTotalField] = useState('');
    const [totalPosition, SetTotalPosition] = useState('');
    const [dueDateField, setDueDateField] = useState('');
    const [dueDatePosition, setDueDatePosition] = useState('');
    const [settingId, setSettingId] = useState(null);
    
    const [region, Region] = useState(null);


    //Orders
    const [isNewOrderDialog, setIsNewOrderDialog] = useState(false);
    const [isSearchProductsLoading, setIsSearchProductsLoading] = useState(false);
    const [isPostSuppplierOrderLoading, setIsPostSuppplierOrderLoading] = useState(false);
    const [isSuggestedItemsVisible, setIsSuggestedItemsVisible] = useState(true);
    const [isSuggestedItemsLoading, setIsSuggestedItemsLoading] = useState(false);
    const [suggestedItems, setSuggestedItems] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);

    const treeTableFuncMap = {
        'globalFilter2': setGlobalFilter2
    };

    //
    const DateFormats = [
        { name: 'Year/Month/Day', code: 'yyyy/mm/dd' },
        { name: 'Year-Month-Day', code: 'yyyy-mm-dd' },
        { name: 'Year Month Day', code: 'yyyy mm dd' },
        { name: 'Day/Month/Year', code: 'dd/mm/yyyy' },
        { name: 'Day-Month-Year', code: 'dd-mm-yyyy' },
        { name: 'Day Month Year', code: 'dd mm yyyy' },
        { name: 'Month/Day/Year', code: 'mm/dd/yyyy' },
        { name: 'Month-Day-Year', code: 'mm-dd-yyyy' },
        { name: 'Month Day Year', code: 'mm dd yyyy' },
        { name: 'Year/Day/Month', code: 'yyyy/dd/mm' },
        { name: 'Year-Day-Month', code: 'yyyy-dd-mm' },
        { name: 'Year Day Month', code: 'yyyy dd mm' },
    ];

    const datePositions = [
        { name: `Value is next to ${dateField}`, code: '0' },
        { name: `Value is Under the Field Name ${dateField}`, code: '1' }
    ];

    const numberPositions = [
        { name: `Value is next to ${numberField}`, code: '0' },
        { name: `Value is Under the Field Name ${numberField}`, code: '1' }
    ];

    const totalPositions = [
        { name: `Value is next to ${totalField}`, code: '0' },
        { name: `Value is Under the Field Name ${totalField}`, code: '1' }
    ];

    const dueDatePositions = [
        { name: `Value is next to ${dueDateField}`, code: '0' },
        { name: `Value is Under the Field Name ${dueDateField}`, code: '1' }
    ];

    const getHeader = (globalFilterKey) => {
        return (
            <div className="p-text-right">
                <h5 className="p-text-left" style={{ marginBottom: '-30px' }}>{supplier?.name} - Invoices</h5>
                <div className="p-input-icon-left">
                    <i className="pi pi-search"></i>
                    <InputText type="search" onInput={(e) => treeTableFuncMap[`${globalFilterKey}`](e.target.value)} placeholder="Search" size="50" />
                </div>
            </div>
        );
    }

    const onDateFormatChange = (e) => {
        setDateFomat(e.value);
    }

    const statuses = [
        { label: 'Paid', value: true },
        { label: 'Not Paid', value: false }
    ];

    let originalRows = {};
    let header2 = getHeader('globalFilter2');

    function valueOfElement(arrayObject, code, fieldName) {
        let value = null;
        arrayObject.forEach(element => {
            if (element.code === code) {
                value = element;

                if (fieldName)
                    value.name = element.name + fieldName;
            }
        });

        return value;
    }
    useEffect(() => {

        setIsLoading(true);
        const user = JSON.parse(window.localStorage.getItem("user"));

        console.log(user)
        if (user == null || user.userType !== '3900') {
            userHasAuthenticated(false);
            //Clear Local storage
            userIsCompanyAdmin(false);
            userIsCompanyCashier(false);
            userIsCompanySupervisor(false);
            window.localStorage.clear();

            //Redirect to login
            history.push("/login");
        }

        let _supplierId = atob(supplierId);
        SetCurrentSupplierId(_supplierId);

        let _config = { headers: { Authorization: `Bearer ${user.token}` } };
        setConfig({
            ..._config
        });

        //Get SupplierInvoiceSetting
        supplierService.getSupplierInvoiceSetting(_supplierId, _config).then(data => {
            if (data) {
                setSettingId(data.id);
                setDateFomat(valueOfElement(DateFormats, data.dateFormat))

                SetDateField(data.dateField);
                SetDatePosition(valueOfElement(datePositions, data.datePosition.toString(), data.dateField));

                setDueDateField(data.dueDateField);
                setDueDatePosition(valueOfElement(dueDatePositions, data.dueDatePosition.toString(), data.dueDateField));

                SetNumberField(data.numberField)
                SetNumberPosition(valueOfElement(numberPositions, data.numberPosition.toString(), data.numberField));

                SetTotalField(data.totalField)
                SetTotalPosition(valueOfElement(totalPositions, data.totalPosition.toString(), data.totalField))


            }
            else {
                setActiveIndex(1);
                setIsInvoiceSettingsInfo(true);
                console.log("No settings found ", data)
            }
        })
            .catch(function (error) {
                console.log(error.response);

                if (error?.response?.status === 401) {
                    userHasAuthenticated(false);
                    //Clear Local storage
                    userIsCompanyAdmin(false);
                    userIsCompanyCashier(false);
                    userIsCompanySupervisor(false);
                    window.localStorage.clear();

                    //Redirect to login
                    history.push("/login");
                }
            });

        //Get Current Year 
        var date = new Date();
        var currentYear = date.getFullYear();
        setSelectedPeriod(currentYear);
        setCurrentPeriod(currentYear);
        setLoggedInUser(user);


        supplierService.getSupplierById(_supplierId, _config).then(data => {
            setSupplier(data);
        });
        //Get Periods for the supplier 
        supplierService.getAllPeriods(_supplierId, _config).then(data => {
            setPeriods(data);

            //Check if current period is in periods 
            if (!(currentYear in data) && data.length) {
                console.log(currentYear)
                currentYear = data[0];
                setSelectedPeriod(data[0]);
                setCurrentPeriod(data[0]);
                //Get All supplier invoices
                supplierService.getAllSupplierInvoices(currentYear, _supplierId, _config).then(data => {
                    setInvoices(data);
                    setIsLoading(false);
                })
                    .catch(function (error) {
                        console.log(error.response);

                        if (error?.response?.status === 401) {
                            userHasAuthenticated(false);
                            //Clear Local storage
                            userIsCompanyAdmin(false);
                            userIsCompanyCashier(false);
                            userIsCompanySupervisor(false);
                            window.localStorage.clear();

                            //Redirect to login
                            history.push("/login");
                        }
                    });
            }
            else {
                setIsLoading(false);
                setPeriods([currentYear])
            }

        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const headerTabList = (
        <span>
            <i className="pi pi-list" style={{ marginRight: "10px" }} />
            <label style={{ cursor: "pointer" }}>Invoice List </label>
        </span>
    );

    const headerTabSettings = (
        <span>
            <i className="pi pi-cog" style={{ marginRight: "10px" }} />
            <label>Invoice Settings</label>
        </span>
    );

    const headerTabReports = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-chart-line" style={{ marginRight: "10px" }} />
            <label>Reports</label>
        </span>
    );

    const headerTabProducts = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-chart-line" style={{ marginRight: "10px" }} />
            <label>Products</label>
        </span>
    );

    const headerTabOrders = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-book" style={{ marginRight: "10px" }} />
            <label>Orders</label>
        </span>
    );

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }


    const subTotalTemplate = (rowData) => {
        let _subTotal = rowData.costPrice * rowData.quantity;

        return <p> {supplierService.formatCurrency(_subTotal)}</p>
    }

    const totalTemplate = () => {
        //calculate subtotal 
        let total = 0;

        orderItems.forEach(element => {
            total += element.costPrice * element.quantity;
        });
        return <p> {supplierService.formatCurrency(total)}</p>
    }

    const priceBodyTemplate = (rowData) => {
        return <p> {supplierService.formatCurrency(rowData.costPrice)}</p>
    }

    const suggestedStatusTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    const removeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltipOptions={{ baseZIndex: 1000 }} tooltip="Remove item from order" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => removeOrderItem(rowData)} />

    }

    const addTemplate = (rowData) => {
        return <Button icon="pi pi-plus" tooltipOptions={{ baseZIndex: 1000 }} tooltip="Add item to the order" className="p-button-rounded p-button-outlined p-button-success p-mr-2" onClick={() => addSuggestedItemToOrder(rowData)} />
    }

    const removeOrderItem = (rowData) => {
        let _orderItems = orderItems.filter(o => o !== rowData);
        setOrderItems(_orderItems);

        //Add back the product to search and suggested items lists
        let _suggestedItems = [...suggestedItems];
        let _searchItems = [...searchProducts];

        _searchItems.push(rowData);
        _suggestedItems.push(rowData);

        setSearchProducts(_searchItems);
        setSuggestedItems(_suggestedItems);
    }

    const addSuggestedItemToOrder = (rowData) => {

        let _suggestedItems = suggestedItems.filter(val => val.productCode !== rowData.productCode);
        let _searchItems = searchProducts.filter(val => val.productCode !== rowData.productCode);
        //Remove Item from suggested Items 
        let _orderItems = [...orderItems];
        _orderItems.push(rowData);

        //Add Item in selected order items 
        setOrderItems(_orderItems);
        //Set new Suggested items
        setSuggestedItems(_suggestedItems);
        setSearchProducts(_searchItems);
    }

    const viewTemplate = (node, column) => {

        if (months.includes(node.data.InvoiceNumber))
            return '';

        ///Get File URL to display 
        return <div>
            <a href={openedInvoice?.File} target='_blank' rel='noopener noreferrer'>
                <i className="pi  pi-file-pdf" style={{ 'fontSize': '2em', color: 'red', cursor: "pointer" }}></i>
            </a>
        </div>;
    }

    const statusTemplate = (node, column) => {
        if (months.includes(node.data.InvoiceNumber))
            return '';

        let isPaid = node.data.IsPaid ? 'Paid' : 'Unpaid';
        return <div>
            {!node.data.IsPaid ? <p style={{ color: 'red' }}>{isPaid}</p> : <p style={{ color: 'green' }}> {isPaid}</p>}
        </div>;
    }

    function onSelectTab(event) {
        if (event != undefined || event != null) {
            setIsLoading(true);
            setSelectedPeriod(periods[event.index])
            supplierService.getAllSupplierInvoices(periods[event.index], currentSupplierId, config).then(data => {
                setIsLoading(false);
                setInvoices(data);
            }).catch(function (error) {
                console.log(error.response);
            });
        }
    }

    function onInvoiceSelect(e) {
        let isHeader = months.includes(e.node.data.InvoiceNumber);

        if (isHeader)
            return;

        setOpenedInvoice(e.node.data);
        // setIsInvoiceOpen(true);
    }
    const InvoiceNumberMonthActionTemplate = (node, column) => {
        let isHeader = months.includes(node.data.InvoiceNumber);

        return (isHeader ? <b>{node.data.InvoiceNumber}</b> : node.data.InvoiceNumber);
    }

    const dynamicAccordionTabs = periods.map((value) => {
        let headerValue = currentPeriod === value ? value + " - Current Year" : value;

        if (value === selectedPeriod)
            return <AccordionTab header={headerValue} key={value}>
                <div className="card">
                    {
                        !isLoading ?
                            <TreeTable dataKey={value} value={invoices} onRowClick={(e) => onInvoiceSelect(e)} globalFilter={globalFilter2} selectionMode="single" reorderableColumns header={header2}>
                                <Column field="InvoiceNumber" header="Invoice #" body={InvoiceNumberMonthActionTemplate} expander sortable></Column>
                                <Column field="InvoiceDate" header="Invoice Date" sortable></Column>
                                <Column field="DueDate" header="Due Date" sortable></Column>
                                <Column field="FileName" header="File Name" sortable></Column>
                                <Column field="Total" header="Total Inc Vat" sortable></Column>
                                <Column field="IsPaid" header="Status" body={statusTemplate} sortable></Column>
                                <Column field="File" header="View" body={viewTemplate} ></Column>
                            </TreeTable>
                            :
                            <DataTable value={invoiceLoading} globalFilter={globalFilter2} header={header2}>
                                <Column header="Month" body={bodyTemplate}></Column>
                                <Column header="Invoice Date" body={bodyTemplate}></Column>
                                <Column header="Invoice #" body={bodyTemplate}></Column>
                                <Column header="Due Date" body={bodyTemplate}></Column>
                                <Column header="Total" body={bodyTemplate}></Column>
                                <Column header="Status" body={bodyTemplate}></Column>
                            </DataTable>
                    }
                </div>
            </AccordionTab>
        else
            return <AccordionTab header={headerValue} key={value}>

            </AccordionTab>
    });

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>

                { activeIndex !== 2 ?
                    <Button disabled={settingId === null} label="Add New Invoice" icon="pi pi-plus" className="p-button-outlined p-button-success p-mr-2" onClick={() => setIsFileUpload(true)} /> :

                    <Button /*disabled={settingId === null}*/ label="Add New Order" icon="pi pi-plus" className="p-button-outlined p-button-success p-mr-2" onClick={() => addNewOrder()} />
                }
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label="Pay Supplier" icon="pi pi-upload" className="p-button-outlined p-button-help" /> */}
                <Button label="Back" className="p-button-outlined" icon="pi pi-arrow-left" onClick={() => history.push('/Suppliers')} />
            </React.Fragment>
        )
    }

    const hideDialog = () => {
        setIsFileLoading(false);
        setIsFileUpload(false);
    }

    const addNewOrder = () => {
        setIsNewOrderDialog(true);
        setIsSearchProductsLoading(true);
        setIsSuggestedItemsLoading(true);

        //Load suggested Items 
        supplierService.getAllSupplierSuggestedProducts(currentSupplierId, config).then(data => {
            setSuggestedItems(data);
            setIsSuggestedItemsLoading(false);
        });

        //Get All supplier Products 
        supplierService.getAllSupplierProducts(currentSupplierId, config).then(data => {
            setSearchProducts(data);
            setIsSearchProductsLoading(false);
        }).catch(function (error) {
            console.log(error.response);
            setIsSearchProductsLoading(false);
        });

    }
    const AddNewInvoiceDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={cancelNewInvoice} />
            {isInvoiceSaving ? <ProgressSpinner style={{ height: '35px' }} /> : <Button disabled={!shouldSaveInvoice} label="Save Invoice" icon="pi pi-check" className="p-button-text" onClick={saveInvoice} />}
        </React.Fragment>
    );

    const ViewInvoiceDialogFooter = (
        <React.Fragment>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => setIsInvoiceOpen(false)} />
        </React.Fragment>
    );

    function saveInvoice() {
        setIsInvoiceSaving(true);
        //currentInvoices[0] - Currently saving a single invoice
        supplierService.postSupplierInvoice(currentInvoices[0], currentSupplierId, config).then(_ => {

            //Get all Periods might be new
            supplierService.getAllPeriods(currentSupplierId, config).then(updatedPeriods => {
                var _selectedPeriod = selectedPeriod;

                if (!(periods === updatedPeriods))
                    setPeriods(updatedPeriods);

                if (updatedPeriods.length === 1) {
                    _selectedPeriod = updatedPeriods[0];
                    setSelectedPeriod(updatedPeriods[0]);
                    setCurrentPeriod(updatedPeriods[0]);
                }

                //Check if the new invoice has a latest period and set the current period to latest 
                if (currentPeriod < updatedPeriods[0]) {
                    setCurrentPeriod(updatedPeriods[0]);
                    setCurrentPeriod(updatedPeriods[0]);
                    _selectedPeriod = updatedPeriods[0];
                }
                //Get All invoices
                supplierService.getAllSupplierInvoices(_selectedPeriod, currentSupplierId, config).then(data => {
                    setInvoices(data);
                    setSelectedPeriod(_selectedPeriod);
                    console.log(_selectedPeriod);
                    setIsInvoiceSaving(false);
                    setOriginalInvoices(null);
                    setCurrentInvoices(null);
                    hideDialog();
                });
            });

        }).catch(function (error) {
            SetFileUploadErrorMessage("Error occured while saving invoice, please try again");
            setIsInvoiceSaving(false);
        });
    }

    function cancelNewInvoice() {
        hideDialog();
        setOriginalInvoices(null);
        setCurrentInvoices(null);
    }

    async function UploadInvoiceAuto(event) {
        if (event.files[0].name) {
            let _invoices = [];
            let _invoice = JSON.parse(event.xhr.response);
            _invoice.file = fileUploadService.processFileLocation(_invoice.file);
            _invoices.push(_invoice);
            setCurrentInvoices(_invoices);
            setShouldSaveInvoice(true);
            setIsFileLoading(false);
        }
    }

    const ErrorInvoiceAuto = (event) => {
        console.log("Error occured", event.xhr);
        SetFileUploadErrorMessage("Error occured while uploading invoice, please try again");
        setIsFileLoading(false);
    }

    const onRowEditInit = (event) => {
        originalRows[event.index] = { ...currentInvoices[event.index] };
        setOriginalInvoices(originalRows);
        setShouldSaveInvoice(false);
    }

    const onRowEditSave = (event) => {
        delete originalRows[event.index];
        setShouldSaveInvoice(true);
    }

    const onRowEditCancel = (event) => {
        let _invoices = [...currentInvoices];
        _invoices[event.index] = originalInvoices[event.index];
        delete originalRows[event.index];
        setCurrentInvoices(_invoices);
        setShouldSaveInvoice(true);
    }

    const inputTextEditor = (productKey, props, field) => {
        let type = field === 'dueDate' || field === 'invoiceDate' ? "date" : "text";
        return <InputText type={type} value={props.rowData[field]} onChange={(e) => onEditorValueChange(productKey, props, e.target.value)} />;
    }

    const invoiceDateEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'invoiceDate');
    }

    const dueDateEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'dueDate');
    }

    const fileNameEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'fileName');
    }

    const totalEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'total');
    }

    const invoiceNumberEditor = (productKey, props) => {
        return inputTextEditor(productKey, props, 'invoiceNumber');
    }

    const statusEditor = (productKey, props) => {
        return (
            <Dropdown value={props.rowData['isPaid']} options={statuses} optionLabel="label" optionValue="value"
                onChange={(e) => onEditorValueChange(productKey, props, e.value)} style={{ width: '100%' }} placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.value}`}>{option.label}</span>
                }} />
        );
    }

    const dataTableFuncMap = {
        'currentInvoices': setCurrentInvoices,
        'orderItems': setOrderItems
    };

    const onEditorValueChange = (productKey, props, value) => {
        let updatedInvoice = [...props.value];
        updatedInvoice[props.rowIndex][props.field] = value;
        dataTableFuncMap[`${productKey}`](updatedInvoice);
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case true:
                return 'Paid';

            case false:
                return 'Not Paid';
            default:
                return 'NA';
        }
    }

    const statusBodyTemplate = (rowData) => {
        return getStatusLabel(rowData?.isPaid);
    }

    const validForm = () => {
        return dateField === '' || dueDateField === '' || totalField === '' || numberField === '' || DateFormat === null ||
            datePosition === '' || dueDatePosition === '' || totalPosition === '' || numberPosition === '';
    }
    const saveInvoiceSettings = () => {
        setIsSaveSettingLoading(true);

        let payload = {
            id: settingId,
            dateField: dateField,
            datePosition: datePosition.code,
            dueDateField: dueDateField,
            dueDatePosition: dueDatePosition.code,
            numberField: numberField,
            numberPosition: numberPosition.code,
            totalField: totalField,
            totalPosition: totalPosition.code,
            dateFormat: DateFormat.code
        }

        supplierService.postSupplierInvoiceSettings(payload, supplier.id, config).then(data => {
            setSettingId(data);
            setActiveIndex(0);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Invoice settings saved', life: 3000 });
            setIsSaveSettingLoading(false);
        })
            .catch(function (error) {
                setIsSaveSettingLoading(false)
                console.log(error?.response);
                toast.current.show({ severity: 'error', summary: 'Failed', detail: "Error saving settings", life: 3000 });
            });
    }

    const sendSupplierOrder = () => {
        setIsPostSuppplierOrderLoading(true);
        console.log("Method Count", orderItems);

        setIsNewOrderDialog(false);

        let payload = {
            NoOfItems : orderItems.length,
            Region : region, // Get AllRegions 
            SupplierOrderItems : orderItems,
        }

        supplierService.PostSupplierOrder(payload,supplierId, config).then(data => {

            console.log(data);
            setIsPostSuppplierOrderLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);

                if (error?.response?.status === 401) {
                    userHasAuthenticated(false);
                    //Clear Local storage
                    userIsCompanyAdmin(false);
                    userIsCompanyCashier(false);
                    userIsCompanySupervisor(false);
                    window.localStorage.clear();

                    //Redirect to login
                    history.push("/login");
                }

                setIsPostSuppplierOrderLoading(false);
            });
    }

    const renderFooter = () => {
        return (
            <div>
                {isInvoiceSettingsInfo && <Button label="Got it!" icon="pi pi-times" onClick={() => setIsInvoiceSettingsInfo(false)} className="p-button-text" />}
                {isNewOrderDialog && <Button label="Cancel" icon="pi pi-times" onClick={() => clearOrder()} className="p-button-text" />}

                {isNewOrderDialog && !isPostSuppplierOrderLoading && <Button label="Send Order" icon="pi pi-times" onClick={() => sendSupplierOrder()} className="p-button-text" />}
                {isNewOrderDialog && isPostSuppplierOrderLoading && <ProgressSpinner style={{ height: '35px', marginRight: '-2em' }}></ProgressSpinner>}
                
            </div>
        );
    }

    const clearOrder = () => {
        setOrderItems([]);
        setIsNewOrderDialog(false);
        setSelectedItem("");
        setIsPostSuppplierOrderLoading(false);
        setIsSuggestedItemsVisible(true);
    }

    const itemTemplate = (item) => {
        return (
            <div className="country-item" onClick={() => addSearchItemToOrder(item)}>
                <img alt={item.name} src={item.image} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${item.productCode.toLowerCase()}`} />
                <div>{item.name.length <= 42 ? item.name : item.name.substring(0, 42) + "..."}</div>
            </div>
        );
    }

    const addSearchItemToOrder = (item) => {
        //Remove Item from suggested Items and from search items
        let _suggestedItems = suggestedItems.filter(val => val.productCode !== item.productCode);
        let _searchProducts = searchProducts.filter(val => val.productCode != item.productCode);
        let _orderItems = [...orderItems];

        _orderItems.push(item);

        //Add Item in selected order items 
        setSearchProducts(_searchProducts);
        setSuggestedItems(_suggestedItems);
        setOrderItems(_orderItems);
    }

    const searchItem = (event) => {
        setTimeout(() => {
            let _filteredItems;
            if (!event.query.trim().length) {
                _filteredItems = [...searchProducts];
            }
            else {
                _filteredItems = searchProducts.filter((item) => {
                    return item.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredItems(_filteredItems);
        }, 250);
    }

    const header1 = (
        <div className="table-header">
            <h5 className="p-m-0">Selected Order Items</h5>
            {
                isSearchProductsLoading ?
                    <ProgressSpinner style={{ height: '35px', marginRight: '-2em' }} />
                    :
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <AutoComplete style={{ width: '350px' }} placeholder={"Search items"} value={selectedItem} suggestions={filteredItems} completeMethod={searchItem} field="name" dropdown forceSelection itemTemplate={itemTemplate} onChange={(e) => setSelectedItem(e.value)} />
                    </span>

            }
        </div>
    );

    const quantityHeader = (
        <p style={{ marginTop: '12px' }} className="quantityClass">No of items <i style={{ marginTop: '2px', color: "blue" }} className="pi pi-question-circle"></i> </p>
    );

    const header3 = (
        <div className="table-header" style={{ textAlign: 'left' }}>
            <h5 className="p-m-0">Suggested Items <Button icon="pi pi-minus" tooltipOptions={{ baseZIndex: 1000 }} tooltip="hide suggested items" className="p-button-rounded p-button-outlined p-button-danger p-button-sm" style={{ marginBottom: '-8px' }} onClick={() => setIsSuggestedItemsVisible(false)} /></h5>
        </div>
    );

    const quantityEditor = (productKey, props) => {
        return inputTextEditorForQauntity(productKey, props, 'code');
    }

    const inputTextEditorForQauntity = (productKey, props, field) => {

        return <InputText type='number' style={{ width: '80px' }} value={props.rowData[field]} onChange={(e) => onEditorQauntityValueChange(productKey, props, e.target.value)} />;
    }

    const onEditorQauntityValueChange = (productKey, props, value) => {
        let updatedOrderItem = [...props.value];
        updatedOrderItem[props.rowIndex][props.field] = value;
        dataTableFuncMap[`${productKey}`](updatedOrderItem);
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className="tabview-demo">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <div className="card">
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={headerTabList} disabled={settingId === null}>
                            {
                                periods.length ?
                                    <Accordion activeIndex={accordionActiveIndex} onTabOpen={(e) => onSelectTab(e)}>
                                        {dynamicAccordionTabs}
                                    </Accordion>
                                    :
                                    <DataTable value={invoiceLoading}>
                                        <Column header="Invoices" body={bodyTemplate}></Column>
                                    </DataTable>
                            }
                        </TabPanel>

                        <TabPanel header={headerTabSettings}> {/* Invoice Settings */}
                            <div className="p-fluid p-col-4" style={{ textAlign: 'left' }}>

                                <h3>Field Mappings</h3><br></br>

                                <div className="p-fluid p-formgrid p-grid" style={{ marginBottom: '10px' }}>
                                    <div className="p-field p-col">
                                        <label htmlFor="InvoiceNumber">Invoice Number# Field Name</label>
                                        <InputText id="InvoiceNumber" value={numberField} tooltip="Copy and paste the field from the invoice" onChange={(e) => SetNumberField(e.target.value)} type="text" />
                                    </div>

                                    <div className="p-field p-col" style={{ marginBottom: '10px' }}>
                                        <label htmlFor="DateForamat">Position of Invoice Number value</label>
                                        <Dropdown disabled={numberField ? false : true} value={numberPosition} options={numberPositions} onChange={(e) => SetNumberPosition(e.target.value)} optionLabel="name" placeholder="Select position of the value" />
                                        {/* Next to Invoice date field,
                                            Under Invoice date field */}
                                    </div>

                                </div>

                                <div className="p-fluid p-formgrid p-grid" style={{ marginBottom: '10px' }}>
                                    <div className="p-field p-col">
                                        <label htmlFor="InvoiceDate">Invoice Date Field Name</label>
                                        <InputText id="InvoiceDate" type="text" value={dateField} onChange={(e) => SetDateField(e.target.value)} placeholder="" tooltip="Copy and paste the field from the invoice" />
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="DateForamat">Position of invoice date value</label>
                                        <Dropdown disabled={dateField ? false : true} value={datePosition} options={datePositions} onChange={(e) => SetDatePosition(e.target.value)} optionLabel="name" placeholder="Select position of the value" />
                                        {/* Next to Invoice date field,
                                            Under Invoice date field */}
                                    </div>

                                </div>


                                <div className="p-fluid p-formgrid p-grid" style={{ marginBottom: '10px' }}>
                                    <div className="p-field p-col">
                                        <label htmlFor="InvoiceDueDate">Invoice Due Date Field Name</label>
                                        <InputText id="InvoiceDueDate" value={dueDateField} tooltip="Copy and paste the field from the invoice" onChange={(e) => setDueDateField(e.target.value)} type="text" />
                                    </div>

                                    <div className="p-field p-col">
                                        <label htmlFor="DateForamat">Position of Invoice due date value</label>
                                        <Dropdown disabled={dueDateField ? false : true} value={dueDatePosition} options={dueDatePositions} onChange={(e) => setDueDatePosition(e.target.value)} optionLabel="name" placeholder="Select position of the value" />
                                        {/* Next to Invoice date field,
                                            Under Invoice date field */}
                                    </div>
                                </div>


                                <div className="p-fluid p-formgrid p-grid">

                                    <div className="p-field p-col">
                                        <label htmlFor="totalAmount">Total Amount(VAT Included) Field Name</label>
                                        <InputText id="totalAmount" value={totalField} tooltip="Copy and paste the field from the invoice" onChange={(e) => SetTotalField(e.target.value)} type="text" />
                                    </div>

                                    <div className="p-field p-col">
                                        <label htmlFor="totalAmount">Position of Total Amount value</label>
                                        <Dropdown disabled={totalField ? false : true} value={totalPosition} options={totalPositions} onChange={(e) => SetTotalPosition(e.target.value)} optionLabel="name" placeholder="Select position of the value" />
                                        {/* Next to Invoice date field,
                                            Under Invoice date field */}
                                    </div>
                                </div>

                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="DateForamat">Date Format from invoice</label>
                                        <Dropdown value={DateFormat} options={DateFormats} onChange={onDateFormatChange} optionLabel="name" placeholder="Select a Date format" />
                                    </div>
                                </div>

                                <div className="p-fluid p-formgrid p-grid p-justify-end">
                                    <div className="p-field p-col-3 " >
                                        {isSaveSettingLoading ? <ProgressSpinner style={{ height: '35px' }} /> : <Button label="Save changes" disabled={validForm()} onClick={() => saveInvoiceSettings()} className="p-button-raised p-button-success" />}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel header={headerTabOrders} /*disabled={settingId === null}*/> {/* orders*/}
                            <SupplierOrders supplierName={supplier?.name} />
                        </TabPanel>

                        <TabPanel header={headerTabReports} disabled={settingId === null}> {/* ] Reports*/}
                            <h1>Add stuff</h1>
                        </TabPanel>


                        <TabPanel header={headerTabProducts}> {/* Products*/}
                            <h1>Import products from spreadsheet</h1>
                        </TabPanel>

                    </TabView>
                </div>
            </div>

            <Dialog visible={isFileUpload} style={{ width: '950px', textAlign: 'left' }} header="New Invoice" modal className="p-fluid" footer={AddNewInvoiceDialogFooter} onHide={hideDialog}>

                <div className="card">
                    {
                        isFileLoading ?
                            <DataTable value={[,]} globalFilter={globalFilter2}>
                                <Column field="fileName" header="fileName" body={bodyTemplate}></Column>
                                <Column field="invoiceDate" header="Invoice Date" body={bodyTemplate}></Column>
                                <Column field="invoiceNumber" header="Invoice #" body={bodyTemplate}></Column>
                                <Column field="dueDate" header="Due Date" body={bodyTemplate}></Column>
                                <Column field="total" header="Total" body={bodyTemplate}></Column>
                                <Column field="isPaid" header="Status" body={bodyTemplate}></Column>
                                <Column field="Edit" header="Confirm" body={bodyTemplate}></Column>
                            </DataTable>
                            :
                            currentInvoices == null && <FileUpload name="file" url={`${apiBaseUrl}Suppliers/UploadSupplierInvoiceDocument?userId=${loggedInUser?.userID}&supplierId=${currentSupplierId}`}
                                onUpload={UploadInvoiceAuto} onError={ErrorInvoiceAuto} onBeforeUpload={() => { setIsFileLoading(true); SetFileUploadErrorMessage('') }} multiple auto={true} accept=".pdf" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>} />
                    }

                    {
                        (!isFileLoading && currentInvoices != null) &&
                        <DataTable value={currentInvoices} editMode="row" dataKey="id" globalFilter={globalFilter2} onRowEditInit={onRowEditInit} onRowEditSave={onRowEditSave} onRowEditCancel={onRowEditCancel}>
                            <Column field="invoiceNumber" header="Invoice #" editor={(props) => invoiceNumberEditor('currentInvoices', props)}></Column>
                            {/* <Column field="fileName" header="File Name" editor={(props) => fileNameEditor('currentInvoices', props)}></Column> */}
                            <Column field="invoiceDate" header="Invoice Date" editor={(props) => invoiceDateEditor('currentInvoices', props)}></Column>
                            <Column field="dueDate" header="Due Date" editor={(props) => dueDateEditor('currentInvoices', props)}></Column>
                            <Column field="total" header="Total" editor={(props) => totalEditor('currentInvoices', props)}></Column>
                            <Column field="isPaid" header="Status" body={statusBodyTemplate} editor={(props) => statusEditor('currentInvoices', props)}></Column>
                            <Column header="Edit" rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>}

                    {fileUploadErrorMessage ? <Message style={{ marginTop: '10px' }} severity="error" summary="Error" text={fileUploadErrorMessage} /> : <></>}
                </div>
            </Dialog>

            <Dialog header="Invoice settings Information" visible={isInvoiceSettingsInfo} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setIsInvoiceSettingsInfo(false)} baseZIndex={1000}>
                <p style={{ textAlign: 'left' }}>Good day {loggedInUser?.username},
                    <br></br>
                    <br></br>
                     Before you can start enjoying the awesome functionality of viewing invoice reports, uploading invoices from pdfs and images we would like you to set some couple of settings to ensure that you receive outstanding experience. Please complete the form below </p>
            </Dialog>

            <Dialog header="Place a new order" visible={isNewOrderDialog} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setIsNewOrderDialog(false)} baseZIndex={1000}>
                <div className="card">
                    <Tooltip target=".quantityClass" content="Click quantity to edit" position='top' baseZIndex={1000} />

                    <DataTable value={orderItems} editMode="cell" header={header1}>
                        <Column body={removeTemplate} style={{ width: '50px' }}></Column>
                        <Column field="barCode" header="Item Code"></Column>
                        <Column field="name" header="Item Name"></Column>
                        <Column field="brandName" header="Item Brand"></Column>
                        <Column field="quantity" header={quantityHeader} style={{ cursor: "pointer" }} editor={(props) => quantityEditor('orderItems', props)}></Column>
                        <Column field="CostPrice" header="Item Price" body={priceBodyTemplate} ></Column>
                        <Column field="status" header="Status" body={bodyTemplate} ></Column>
                        <Column field="Total" header="Sub Total" body={subTotalTemplate} ></Column>
                    </DataTable>
                    <DataTable value={[,]}>
                        <Column header="Total" ></Column>
                        <Column ></Column>
                        <Column ></Column>
                        <Column ></Column>
                        <Column ></Column>
                        <Column ></Column>
                        <Column ></Column>
                        <Column field="Total" header={totalTemplate()}></Column>
                    </DataTable>


                    {/*Control visiblity of the suggested items   */}
                    {isSuggestedItemsVisible && isSuggestedItemsLoading &&

                        <DataTable value={[, ,]} header={header3} >
                            {/* {isSuggestedItemsLoading } */}
                            {/* <Column body={addTemplate} style={{width:'50px'}}></Column> */}
                            <Column field="productCode" header="Item Code" body={bodyTemplate}></Column>
                            <Column field="name" header="Item Name" body={bodyTemplate}></Column>
                            <Column field="brandName" header="Item Brand" body={bodyTemplate} ></Column>
                            <Column field="quantityOnHand" header="OnHand" body={bodyTemplate} ></Column>
                            <Column field="CostPrice" header="Item Price" body={bodyTemplate} ></Column>
                            <Column field="status" header="Status" body={bodyTemplate} ></Column>
                        </DataTable>
                    }

                    {isSuggestedItemsVisible && suggestedItems?.length > 0 && !isSuggestedItemsLoading &&

                        <DataTable value={suggestedItems} header={header3} >
                            <Column body={addTemplate} style={{ width: '50px' }}></Column>
                            <Column field="barCode" header="Item Code"></Column>
                            <Column field="name" header="Item Name"></Column>
                            <Column field="brandName" header="Item Brand" ></Column>
                            <Column field="quantityOnHand" header="OnHand"></Column>
                            <Column field="CostPrice" header="Item Price" body={priceBodyTemplate} ></Column>
                            <Column field="status" header="Status" body={suggestedStatusTemplate} ></Column>
                        </DataTable>
                    }
                </div>
            </Dialog>

        </div>
    );
}
export default SingleSupplier