import "./Suppliers.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router-dom";
import SupplierService from '../Service/SupplierService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { useAppContext } from "../libs/contextLib";
import { Tooltip } from 'primereact/tooltip';
const Suppliers = () => {

    let emptySupplier = {
        name: "",
        website: "",
        email: "",
        telNumber: "",
        isDeleted: false,
        address: ""
    };

    const [suppliers, setSuppliers] = useState(null);
    const [supplierDialog, setSupplierDialog] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [deleteSupplierDialog, setDeleteSupplierDialog] = useState(false);
    const [deleteSuppliersDialog, setDeleteSuppliersDialog] = useState(false);
    const [supplier, setSupplier] = useState(emptySupplier);
    const [selectedSuppliers, setSelectedSuppliers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const suppliersLoading = new Array(3);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [config, setConfig] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const supplierService = new SupplierService();
    const { userHasAuthenticated, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor} = useAppContext();


    useEffect(() => {
        setInitialLoad(true);
        //Get UserID 
        const user =  JSON.parse(window.localStorage.getItem("user")); 

        if(user == null || user.userType !== '3900')
        {
            history.push("/NotFound"); 
            return; 
        } 
        else{
            setLoggedInUser(user);

            let _config = {headers: { Authorization: `Bearer ${user.token}` }};
            setConfig({
                ..._config
            });

            supplierService.getAllSuppliers(_config).then(data => {
                setSuppliers(data);
                setInitialLoad(false);
            }).catch(function (error) {
                console.log(error.response);
    
                if(error?.response?.status === 401)
                {
                   userHasAuthenticated(false);
                   //Clear Local storage
                   userIsCompanyAdmin(false);
                   userIsCompanyCashier(false);
                   userIsCompanySupervisor(false);
                   window.localStorage.clear();
               
                   //Redirect to login
                    history.push("/login");
                } 
            });
        }
            
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setSupplier(emptySupplier);
        setSubmitted(false);
        setSupplierDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSupplierDialog(false);
    }

    const hideDeleteSupplierDialog = () => {
        setDeleteSupplierDialog(false);
    }

    const hideDeleteSuppliersDialog = () => {
        setDeleteSuppliersDialog(false);
    }

    const saveSupplier = () => {
        setSubmitted(true);
        setIsLoading(true);

        if (supplier.name.trim()) {
            let _suppliers = [...suppliers];
            let _supplier = {...supplier};
            if (supplier.id) {
                const index = findIndexById(supplier.id);
                _suppliers[index] = _supplier;
                supplierService.updateSupplier(_supplier,config).then(data => {
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Supplier Added', life: 3000 });
                    setSuppliers(_suppliers);
                }).catch(function (error) {      
                    setIsLoading(false);
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
                  });
            }
            else {
                //Call save supplier End point
                supplierService.postSupplier(_supplier,config).then(data => {
                    setIsLoading(false);
                    _suppliers.push(data);
                    setSuppliers(_suppliers);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Supplier Added', life: 3000 });
                }).catch(function (error) {      
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
                    setIsLoading(false);
                  });
            }

            setSupplierDialog(false);
            setSupplier(emptySupplier);
        }
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < suppliers.length; i++) {
            if (suppliers[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const editSupplier = (supplier) => {
        setSupplier({...supplier});
        setSupplierDialog(true);
    }

    const confirmDeleteSupplier = (supplier) => {
        setSupplier(supplier);
        setDeleteSupplierDialog(true);
    }

    const deleteSupplier = () => {
        let _suppliers = [...suppliers];
        let _supplier = {...supplier};
        _supplier.isDeleted = true;

        const index = findIndexById(supplier.id);
        _suppliers[index] = _supplier;
        supplierService.updateSupplier(_supplier,config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Supplier Deactivated on the system', life: 8000 });
            setSuppliers(_suppliers);
        }).catch(function (error) {      
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
          });

        setDeleteSupplierDialog(false);
        setSupplier(emptySupplier);
    }

    const reActivateSupplier = (supplierToActivate) => {
        let _suppliers = [...suppliers];
        let _supplier = {...supplierToActivate};
        _supplier.isDeleted = false;

        const index = findIndexById(supplierToActivate.id);
        _suppliers[index] = _supplier;
        supplierService.updateSupplier(_supplier,config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Supplier Re-Activated on the system', life: 8000 });
            setSuppliers(_suppliers);
        }).catch(function (error) {      
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
          });

        setDeleteSupplierDialog(false);
        setSupplier(emptySupplier);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const deleteSelectedSuppliers = () => {
        let _suppliers = suppliers.filter(val => !selectedSuppliers.includes(val));
        setSuppliers(_suppliers);
        setDeleteSuppliersDialog(false);
        setSelectedSuppliers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Suppliers Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _supplier = {...supplier};
        _supplier[`${name}`] = val;

        setSupplier(_supplier);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add New Supplier" icon="pi pi-plus" className="p-button-success p-mr-2 p-button-outlined" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-outlined" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const addressBodyTemplate = (rowData) => {
        return <span >{ rowData.address.length > 33 ? rowData.address.substring(0, 33) + "..." : rowData.address}</span>;
    }

    const websiteBodyTemplate = (rowData) => {
        return <a href={rowData.website}  target={rowData.website} rel="noopener noreferrer">{rowData.website}</a>;
    }

    const statusBodyTemplate = (rowData) => {
        return <span >{rowData.isDeleted ? 
        <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} tooltip={rowData.daysLeft + " days left"} label="Inactive" className="p-button-rounded p-button-outlined p-button-danger" /></Tag> 
        : <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} label="Active" className="p-button-rounded p-button-outlined p-button-success" /></Tag>}</span>;
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.isDeleted ? 
                <Button icon="pi pi-check-circle" tooltip="Re activate supplier" className="p-button-rounded p-button-outlined p-button-success" onClick={() => reActivateSupplier(rowData)} />
                : 
                <div>
                    <Button icon="pi pi-pencil" tooltip="Edit supplier" className="p-button-rounded p-button-outlined p-button-warning p-mr-2" onClick={() => editSupplier(rowData)} />
                    <Button icon="pi pi-ban" tooltip="Delete supplier" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => confirmDeleteSupplier(rowData)} /> 
                </div>
                }
                
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Suppliers</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search"  onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const supplierDialogFooter = (
        <React.Fragment>
            <Button  label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={saveSupplier} />
        </React.Fragment>
    );
    const deleteSupplierDialogFooter = (
        <React.Fragment>
            <Message severity="warn" text="Supplier will be deleted in 30 days" />
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSupplierDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSupplier} />
        </React.Fragment>
    );
    const deleteSuppliersDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSuppliersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedSuppliers} />
        </React.Fragment>
    );

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }
    
    const supplierNameTemplate = (node) => {
        
        let params = btoa(node.id);

        return !node.isDeleted ? <p style={{ cursor: "pointer", color:'#598bc4' }} onClick={() => history.push(`/Supplier/${params}`)}>{node.name}</p> :<p>{node.name}</p> 
    }
    return (
        <div className="datatable-crud-products">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                {
                    !initialLoad ?
                    <DataTable ref={dt} value={suppliers} selection={selectedSuppliers} onSelectionChange={(e) => setSelectedSuppliers(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} suppliers"
                        globalFilter={globalFilter}
                        header={header}>

                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="Name" body={supplierNameTemplate} sortable></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="telNumber" header="Tel Number" ></Column>
                        <Column field="website" header="Website" body={websiteBodyTemplate}></Column>
                        <Column field="address" header="Address" body={addressBodyTemplate} ></Column>
                        <Column field="isDeleted" header="Status" body={statusBodyTemplate} sortable></Column>
                        <Column header="Action" body={actionBodyTemplate}></Column>
                    </DataTable>
                    :
                    <DataTable value={suppliersLoading} className="p-datatable-striped">
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="Name" body={bodyTemplate}></Column>
                        <Column field="email" header="Email" body={bodyTemplate}></Column>
                        <Column field="telNumber" header="Tel Number" body={bodyTemplate}></Column>
                        <Column field="website" header="Website" body={bodyTemplate}></Column>
                        <Column field="address" header="Address" body={bodyTemplate} ></Column>
                        <Column field="isDeleted" header="Status" body={bodyTemplate} ></Column>
                        <Column header="Action" body={bodyTemplate}></Column>
                    </DataTable>
                }
                    {isLoading && <ProgressSpinner />}
            </div>

{/* Adding new Supplier */}
            <Dialog visible={supplierDialog} style={{ width: '450px', textAlign:'left' }} header="Supplier Details" modal className="p-fluid" footer={supplierDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={supplier.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !supplier.name })} />
                    {submitted && !supplier.name && <small className="p-error">Name is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="email">Email</label>
                    <InputText id="email" type="email" value={supplier.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !supplier.email })} />
                    {submitted && !supplier.email && <small className="p-error">Email is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="telNumber">Telephone Number</label>
                    <InputText id="telNumber" type={Number} value={supplier.telNumber} onChange={(e) => onInputChange(e, 'telNumber')} required className={classNames({ 'p-invalid': submitted && !supplier.telNumber })} />
                    {submitted && !supplier.telNumber && <small className="p-error">Telephone Number is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="website">Website URL</label>
                    <InputText id="website" value={supplier.website} onChange={(e) => onInputChange(e, 'website')} required className={classNames({ 'p-invalid': submitted && !supplier.website })} />
                    {submitted && !supplier.website && <small className="p-error">Website URL is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="address">Address</label>
                    <InputText id="address" value={supplier.address} onChange={(e) => onInputChange(e, 'address')} required className={classNames({ 'p-invalid': submitted && !supplier.address })} />
                    {submitted && !supplier.address && <small className="p-error">Address is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteSupplierDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSupplierDialogFooter} onHide={hideDeleteSupplierDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {supplier && <span>Are you sure you want to Deactivate <b>{supplier.name}'s account</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteSuppliersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSuppliersDialogFooter} onHide={hideDeleteSuppliersDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {supplier && <span>Are you sure you want to delete the selected suppliers?</span>}
                </div>
            </Dialog>
        </div>
    );
}
export default Suppliers