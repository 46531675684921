import axios from 'axios';
import { FileUpload } from 'primereact/fileupload';
import API_URL, { HOST_URL } from './ApiUrl';

export class FileUploadService {

   processImageLocation(image) {
      if(image)
          return  image.replace("localhost","localhost:5000");
      else
          return  HOST_URL + '/Images/defaultPostImage.jpg';
    }

   getImageUrl(key, config) {
        return axios.get(`${API_URL}File/GetImage?key=${key}`,config).then(res => {
            return res.data.result;
        });
    }

    // uploadFile({files}) {
    //     alert("File to be uploaded ");
    //     let fileNames = [];
    //     let user = JSON.parse(localStorage.getItem("user"));
        
    //     console.log(user);

    //     var formData = new FormData();
        
    //     files.map((file, index) => {
    //       formData.append(`image`, file); //single file upload 
    //       var url = apiBaseUrl + 'File/FileUpload?userId=' + user.userID;
    //       axios.post(url,
    //         formData,
    //         {
    //           headers: {
    //             'Content-Type': 'multipart/form-data'
    //           }
    //         })
    //         .then(success => {
    //           fileNames.push(success.data);
    //           return fileNames;
    //         })
    //         .catch(error => console.log(error));
    //     });//end of map function
    //   }
}