import "./Products.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProductService from '../Service/ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import ProductSettingsTab from './ProductSettingsTab'
import { Dropdown } from 'primereact/dropdown';
import SupplierService from '../Service/SupplierService';
import { FileUploadService } from '../Service/FileUploadService';
import { useHistory } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TreeTable } from 'primereact/treetable';
import TransactionService from '../Service/TransactionService';
import { useAppContext } from "../libs/contextLib";
import API_URL, { HOST_URL } from "../Service/ApiUrl";

const Products = () => {
    let emptyProduct = {
        id: null,
        productCode: '',
        barCode: '',
        name: '',
        categoryName: '',
        brandName: '',
        supplierName: '',
        image: null,
        description: '',
        price: 0,
        costPrice: 0,
        taxable: '',
        quantity: 1,
        quantityOnHand: 0,
        rating: 0,
        status: 'INSTOCK'
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [barCode, setBarCode] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);//Set it to 1 if there's no products
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [productTransactions, setProductTransactions] = useState([]);
    const [stockLevel, setStockLevel] = useState(0);
    const productsLoading = new Array(3);
    const { userHasAuthenticated, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor} = useAppContext();
    // const [category, setCategory] = useState('');
    // const [brand, setBrand] = useState('');
    // const [supplier, setSupplier] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [vatOptions, setVatOptions] = useState([]);
    const [selectedProductForTransaction, setSelectedProductForTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [isLoadingSubmittedProduct, setIsLoadingSubmittedProduct] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [productImage, setProductImage] = useState(null);
    const [productImageKey, setProductImageKey] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isExistingProduct, setIsExistingProduct] = useState(false);
    const [config, setConfig] = useState(null);

    const productService = new ProductService();
    const supplierService = new SupplierService();
    const fileUploadService = new FileUploadService();
    const transactionService = new TransactionService();
    const apiBaseUrl = API_URL;
    const history = useHistory();

    //Transaction Item 
    const [isTransactionItemsLoading, setTransactionItemsIsLoading] = useState(false);
    const [isSingleTransaction, setIsSingleTransaction] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [months,] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);

    let fileRef = React.createRef(null);

    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user == null)
            return;

        if (user.userType !== '3900') {
            history.push("/NotFound");
            return;
        }
    
        setLoggedInUser(user);
        onLoad(user);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onLoad(user) {

        var date = new Date();
        var currentYear = date.getFullYear();
        setCurrentPeriod(currentYear);

        setIsInitialLoad(true);
        if (!user)
            user = loggedInUser;

               
        let _config = {headers: { Authorization: `Bearer ${user.token}` }};
        setConfig({
            ..._config
        });

        productService.getAllProducts(_config).then(data => {
            setProducts(data)
            setIsInitialLoad(false);
        }).catch(function (error) {
                console.log(error.response);

                if(error?.response?.status === 401)
                 {
                    userHasAuthenticated(false);
                    //Clear Local storage
                    userIsCompanyAdmin(false);
                    userIsCompanyCashier(false);
                    userIsCompanySupervisor(false);
                    window.localStorage.clear();
                
                    //Redirect to login
                     history.push("/login");
                 } 
            });

        //Set Stock Level
        productService.getProductQuatityLevel(_config).then(data => setStockLevel(data));

        //Brands categories saved 
        productService.getAllCategories(_config).then(data =>{
            setCategories(data);

            if (data?.length <= 0) {
                setActiveIndex(1);
            }
        });
        //Get Brands saved
        productService.getAllBrands(_config).then(data => {
            setBrands(data);

            if (data?.length <= 0) {
                setActiveIndex(1);
        }
        });
        //Get Suppliers
        supplierService.getAllSuppliers(_config).then(data => setSuppliers(data));
        //VAT Options 
        setVatOptions([{ name: "VAT-Taxable" }, { name: "VAT- None Taxable" }]);
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('zu-ZA', { style: 'currency', currency: 'ZAR' });
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setBarCode(null);
        setIsExistingProduct(false);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setIsEdit(false);
        setProduct(emptyProduct);
        setBarCode('');
        setProductImage('');
        setSubmitted(false);
        setProductDialog(false);
        setIsLoadingImage(false);
        setIsLoadingSubmittedProduct(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideSingleProductTransactionDialog = () => {
        setSelectedProductForTransaction(null);
        setIsSingleTransaction(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);
        setIsLoadingSubmittedProduct(true);
        setIsEdit(false);

        if (product.name.trim()) {
            let _products = [...products];
            let _product = { ...product };
            _product.barCode = barCode;
            _product.productCode = barCode;
            _product.image = productImageKey;

            if (product.id && !isExistingProduct) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                
                productService.updateProduct(_product, config).then(_ => {
                    _product.image = productImage;
                    _products[index] = _product;
                    setProducts(_products);
                    setProduct(emptyProduct);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
                    setIsLoadingSubmittedProduct(false);
                    setProductDialog(false);
                });
            }
            else if (isExistingProduct) {
                console.log("Update Existing Product ");
                const index = findIndexById(product.id);

                productService.updateExistingScannedProduct(_product, config).then(_ => {

                    _product.quantityOnHand = _product.quantity + _product.quantityOnHand;
                    _product.status = _product.quantityOnHand >= stockLevel ? "INSTOCK" : "LOWSTOCK";
                    _products[index] = _product;
                    setProducts(_products);
                    setProduct(emptyProduct);
                    setBarCode(null);
                    setProductImage(null);
                    setIsExistingProduct(false);
                    setIsLoadingSubmittedProduct(false)
                    setProductDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Added', life: 3000 });
                }).catch(function (error) {
                    
                if(error?.response?.status === 401)
                {
                   userHasAuthenticated(false);
                   //Clear Local storage
                   userIsCompanyAdmin(false);
                   userIsCompanyCashier(false);
                   userIsCompanySupervisor(false);
                   window.localStorage.clear();
               
                   //Redirect to login
                    history.push("/login");
                } 
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
                    // setIsLoading(false);
                });
            }
            else {
                //add new product
                productService.postProduct(_product, config).then(data => {
                    _products.push(data);
                    setProducts(_products);
                    setProduct(emptyProduct);
                    setBarCode(null)
                    setProductImage(null);
                    setIsLoadingSubmittedProduct(false);
                    setProductDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Added', life: 3000 });
                }).catch(function (error) {
                    
                if(error?.response?.status === 401)
                {
                   userHasAuthenticated(false);
                   //Clear Local storage
                   userIsCompanyAdmin(false);
                   userIsCompanyCashier(false);
                   userIsCompanySupervisor(false);
                   window.localStorage.clear();
               
                   //Redirect to login
                    history.push("/login");
                }
                console.log(error?.response);

                    toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
                    setIsLoading(false);
                });
            }
        }
    }

    const editProduct = (product) => {
        setIsEdit(true);
        setBarCode(product.barCode);
        setProductImage(product.image);
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _products = products.filter(val => val !== product);

        productService.deleteProduct(product, config).then(data => {
            setProducts(_products);
            setDeleteProductDialog(false);
            setProduct(emptyProduct);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
        }).catch(function (error) {
            console.log(error.response);
            toast.current.show({ severity: 'error', summary: 'Failed', detail: error?.response?.data, life: 3000 });
        });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products?.length; i++) {
            if (products[i]?.id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const findIndexByBarcode = (barCode) => {
        let index = -1;
        for (let i = 0; i < products?.length; i++) {
            if (products[i]?.barCode === barCode) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.exportFilename = Date.now();
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _products = products.filter(val => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New Product" icon="pi pi-plus" className="p-button-success p-button-outlined  p-mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-button-outlined " onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-outlined " onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const imageBodyTemplate = (rowData) => {
        return <img src={rowData.image || fileUploadService.processImageLocation(null)} alt={rowData.image} height="100px" width="100px"  /*className="product-image"*/ />
    }

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    }

    const costPriceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.costPrice);
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    function onClickProductImage() {
        setIsLoadingImage(true);
        fileRef.current.fileInput.click();
    }

    const UploadProductImageAuto = (event) => {
        if (event.files[0].name) {
            const data = JSON.parse(event.xhr.response);
            setProductImage(data.imageUrl);
            setProductImageKey('Images/' + event.files[0].name);
            setIsLoadingImage(false);
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-warning p-mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Products</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" autoComplete="off" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" disabled={!barCode || barCode?.length < 5} icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </React.Fragment>
    );

    function onClickRefresh() {
        onLoad();
        //Refresh page 
    }

    function setProductBarcodeFromScanner(value) {
        setBarCode(value);
        //Check if product exists in the list of products 
        if (value.length > 10) {
            let index = findIndexByBarcode(value);

            if (index !== -1) {
                setProduct(products[index]);
                setProductImage(products[index].image);
                setIsExistingProduct(true);

            }
            else {
                setIsExistingProduct(false);
            }
        }
        else {
            setIsExistingProduct(false);
        }


    }
    const headerTabList = (
        <span style={{ cursor: "pointer" }} onClick={() => onClickRefresh()}>
            <i className="pi pi-list" style={{ marginRight: "10px" }} onClick={() => onClickRefresh()} />
            <label style={{ cursor: "pointer" }} onClick={() => onClickRefresh()}>Product List </label>
        </span>
    );

    const headerTabSettings = (
        <span>
            <i className="pi pi-cog" style={{ marginRight: "10px" }} />
            <label>Product Settings</label>
        </span>
    );

    const headerTabReports = (
        <span>
            <i className="pi pi-chart-line" style={{ marginRight: "10px" }} />
            <label>Product Reports</label>
        </span>
    );

    
    const productSubmittedProgress = (
        <span>
            <ProgressSpinner style={{ width: "30px", height: "30px" }}/>
        </span>
    );

    const onClickProduct = (event) => {
        let key = event.barCode;
        setSelectedProductForTransaction(event);
        if (!months.includes(key)) {
            setIsSingleTransaction(true);
            loadTransactionItems(key)
        }
    }

    const loadTransactionItems = (transactionId) => {
        setTransactionItemsIsLoading(true);

        transactionService.getTreeTransactionsByProductCode(config,currentPeriod, transactionId).then(data => {
            setTransactionItemsIsLoading(false);
            setProductTransactions(data);
            // setSelectedTransactionTotal(data.totalTransactionPrice);
            // setSelectedTransactionID(data.transactionId);
        }).catch(function (error) {
            console.log(error);
        });

    }

    const priceExcVATtotalVATTemplate = (node) => {
        let amount = node.data.PriceExcVAT;
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{formatCurrency(node.data.PriceExcVAT)}</p> : <p> {formatCurrency(node.data.PriceExcVAT)}</p>}
        </div>;
    }

    const priceIncVATTemplate = (node) => {
        let amount = node.data.PriceIncVAT;
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{formatCurrency(node.data.PriceIncVAT)}</p> : <p> {formatCurrency(node.data.PriceIncVAT)}</p>}
        </div>;
    }

    const totalDiscountIncVATTemplate = (node) => {
        let amount = node.data.TotalDiscountIncVAT;
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{formatCurrency(node.data.PriceIncVAT)}</p> : <p> {formatCurrency(node.data.TotalDiscountIncVAT)}</p>}
        </div>;
    }

    const totalPriceIncVATTemplate = (node) => {
        let amount = node.data.TotalPriceIncVAT;
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{formatCurrency(node.data.TotalPriceIncVAT)}</p> : <p> {formatCurrency(node.data.TotalPriceIncVAT)}</p>}
        </div>;
    }

    const totalVATTemplate = (node) => {
        let amount = node.data.TotalVAT;
        return <div>
            {amount < 0 ? <p style={{ color: 'red' }}>{formatCurrency(node.data.TotalVAT)}</p> : <p> {formatCurrency(node.data.TotalVAT)}</p>}
        </div>;
    }

    const transactionMonthActionTemplate = (node, column) => {
        let isHeader = months.includes(node.data.TransactionMonth);

        return (isHeader ? <b>{node.data.TransactionMonth}</b> : "");
    }

    const productCodectionTemplate = (node) => {
        return <p style={{ cursor: "pointer", color:'#598bc4' }} onClick={() => onClickProduct(node)}>{node.productCode}</p>
    }

    return (
        <div className="datatable-crud-products">
            <Toast ref={toast} />

            <div className="tabview-demo">
                <div className="card">
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={headerTabList}> {/* Product List */}
                            <div className="card">
                                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                                {
                                    !isInitialLoad ?
                                        <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            globalFilter={globalFilter}
                                            header={header}>

                                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                            <Column field="productCode" header="Code" sortable body={productCodectionTemplate}></Column>
                                            <Column field="name" header="Name" sortable></Column>
                                            <Column header="Image" body={imageBodyTemplate}></Column>
                                            <Column field="price" header="Selling Price" body={priceBodyTemplate} sortable></Column>
                                            <Column field="CostPrice" header="Cost Price" body={costPriceBodyTemplate} sortable></Column>
                                            <Column field="categoryName" header="Category" sortable></Column>
                                            <Column field="brandName" header="Brand" sortable></Column>
                                            <Column field="supplierName" header="Supplier" sortable></Column>
                                            <Column field="quantityOnHand" header="OnHand" sortable></Column>
                                            <Column field="status" header="Status" body={statusBodyTemplate} sortable></Column>
                                            <Column body={actionBodyTemplate}></Column>
                                        </DataTable>
                                        :
                                        <DataTable ref={dt} value={productsLoading} header={header}>

                                            <Column field="productCode" header="Code" body={bodyTemplate}></Column>
                                            <Column field="name" header="Name" body={bodyTemplate}></Column>
                                            <Column header="Image" body={bodyTemplate}></Column>
                                            <Column field="price" header="Selling Price" body={bodyTemplate} ></Column>
                                            <Column field="CostPrice" header="Cost Price" body={bodyTemplate} ></Column>
                                            <Column field="categoryName" header="Category" body={bodyTemplate} ></Column>
                                            <Column field="brandName" header="Brand" body={bodyTemplate} ></Column>
                                            <Column field="supplierName" header="Supplier" body={bodyTemplate} ></Column>
                                            <Column field="quantityOnHand" header="OnHand" body={bodyTemplate} ></Column>
                                            <Column field="status" header="Status" body={bodyTemplate} ></Column>
                                        </DataTable>
                                }
                            </div>

                        </TabPanel>

                        <TabPanel header={headerTabSettings}> {/* Product Settings */}
                            <ProductSettingsTab />
                        </TabPanel>

                        <TabPanel header={headerTabReports}> {/* Product Reports*/}
                        <h5 style={{textAlign:'left'}}>
                            . How long it takes for a product to be sold Reports <br></br>
                            . Products that have been in stock for a while <br></br>
                            . Fast moving products <br></br>
                            .
                        </h5>
                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <Dialog visible={productDialog} style={{ width: '550px' }} header="Product Details" modal className="p-fluid" footer={isLoadingSubmittedProduct ? productSubmittedProgress : productDialogFooter} onHide={hideDialog}>
                {/* If Barcode is not yet Scanned show barCode scanner gif image or User can enter code by themself minimum 4 characters */}

                <div className="p-field">
                    <InputText id="barCode" autoComplete="off" disabled={isEdit || isExistingProduct} value={barCode || ''} placeholder="Scan product barCode  or enter it manually" onChange={(e) => setProductBarcodeFromScanner(e.target.value)} required autoFocus />
                </div>
                {!barCode || barCode?.length < 8
                    ?
                    <img src={process.env.PUBLIC_URL + '/scanProduct.gif'} width={'509px'} height={'300px'} alt="loading..." />
                    : <>
                        {
                            isLoadingImage ? 
                            <ProgressSpinner/>
                            :
                            //  <img src={productImage ? fileUploadService.getImageUrl(productImage,config) : null} disabled={isExistingProduct} width={'50%'} height={'220px'} onClick={onClickProductImage} style={{ cursor: "pointer" }} alt={product.image} />
                             <img src={productImage} disabled={isExistingProduct} width={'50%'} height={'220px'} onClick={onClickProductImage} style={{ cursor: "pointer" }} alt={product.image} />
                        }

                        <div style={{ display: 'none' }}>
                            <FileUpload id="productUploadImage" ref={fileRef}
                                mode="basic" name="image" url={`${apiBaseUrl}File/FileUploadProductImage?userId=${loggedInUser ? loggedInUser.userID : "12345"}`}
                                accept="image/*" maxFileSize={1000000} onUpload={UploadProductImageAuto} auto={true} chooseLabel="Browse"/>
                        </div>

                        <div className="p-field" style={{ textAlign: 'left' }}>
                            <label htmlFor="name">Name</label>
                            <InputText id="name" autoComplete="off" value={product.name} disabled={isExistingProduct} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !product.name })} />
                            {submitted && !product.name && <small className="p-error">Name is required.</small>}
                        </div>

                        <div className="p-field" style={{ textAlign: 'left' }}>
                            <div className="p-formgrid p-grid">
                                <div className="p-col-6">
                                    <Dropdown optionLabel="name" optionValue="name" disabled={isExistingProduct} value={product.supplierName} options={suppliers} onChange={(e) => onInputChange(e, 'supplierName')} placeholder="Select a Supplier" />
                                </div>

                                <div className=" p-col-6">
                                    <Dropdown optionLabel="name" optionValue="name" disabled={isExistingProduct} value={product.categoryName} options={categories} onChange={(e) => onInputChange(e, 'categoryName')} placeholder="Select a Category" />
                                </div>

                                <div className="p-col-6" style={{ marginTop: '15px' }}>
                                    <Dropdown optionLabel="name" optionValue="name" disabled={isExistingProduct} value={product.brandName} options={brands} onChange={(e) => onInputChange(e, 'brandName')} placeholder="Select a Brand" />
                                </div>
                                <div className="p-col-6" style={{ marginTop: '15px' }}>
                                    <Dropdown optionLabel="name" optionValue="name" disabled={isExistingProduct} value={product.taxable} options={vatOptions} onChange={(e) => onInputChange(e, 'taxable')}/*onChange={(e) => setIsVatApplicable(e.value)}*/ placeholder="Is VAT Applicable" />
                                </div>
                            </div>
                        </div>

                        <div className="p-field" style={{ textAlign: 'left' }}>
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={product.description} disabled={isExistingProduct} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>

                        <div className="p-formgrid p-grid" style={{ textAlign: 'left' }}>
                            <div className="p-field p-col">
                                <label htmlFor="price">Price(Incl VAT)</label>
                                <InputNumber id="price" value={product.price} min={0} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="currency" currency="ZAR" locale="zu-ZA" />
                            </div>

                            <div className="p-field p-col">
                                <label htmlFor="price">Cost Price(Incl VAT)</label>
                                <InputNumber id="price" value={product.costPrice} min={0} onValueChange={(e) => onInputNumberChange(e, 'costPrice')} mode="currency" currency="ZAR" locale="zu-ZA" />
                            </div>

                            <div className="p-field p-col">
                                <label htmlFor="quantity">Quantity</label>
                                <InputNumber id="quantity" disabled={isEdit} min={0} value={product.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} integeronly />
                            </div>
                        </div>

                    </>
                }
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete the selected products?</span>}
                </div>
            </Dialog>

            <Dialog header={currentPeriod + " Transactions for " + selectedProductForTransaction?.name } visible={isSingleTransaction}   footer={""} onHide={hideSingleProductTransactionDialog}>
                <div className="card">
                    <TreeTable  value={productTransactions} reorderableColumns>
                        <Column field="TransactionMonth" header="Month" body={transactionMonthActionTemplate} expander></Column>
                        <Column field="TransactionId" header="TransactionId "></Column>
                        <Column field="ProductBarCode" header="BarCode"></Column>
                        <Column field="Date" header="Transaction Date"></Column>
                        <Column field="TransactionType" header="Transaction Type"></Column>
                        <Column field="SuppierName" header="Suppier"></Column>
                        <Column field="QuantitySold" header="Quantity Sold"></Column>
                        <Column field="TotalDiscountIncVAT" header="Discount IncVAT" body={totalDiscountIncVATTemplate}></Column>
                        <Column field="TotalPriceIncVAT" header="Total Price IncVAT" body={totalPriceIncVATTemplate}></Column>
                        <Column field="TotalVAT" header="Total VAT" body={totalVATTemplate}></Column>
                    </TreeTable>
                </div>
            </Dialog>
        </div>
    );
}
export default Products