import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound"
import Login from "./containers/Login"
import Products from "./containers/Products";
import Suppliers from "./containers/Suppliers";
import Employees from "./containers/Employees";
import TestPage from "./containers/TestPage";
import Cashier from "./containers/Cashier";
import AdminTransactions from "./containers/AdminTransactions";
import SingleSupplier from "./containers/SingleSupplier";
import CompanySettings from "./containers/CompanySettings";
import CompanyRegions from "./containers/CompanyRegions";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>

      <Route exact path="/products">
        <Products/>
      </Route>

      <Route exact path="/suppliers">
        <Suppliers/>
      </Route>

      <Route exact path="/customers">
        <TestPage/>
      </Route>

      <Route exact path="/employees">
        <Employees/>
      </Route>
      
      <Route exact path="/transact">
        <Cashier/>
      </Route>

      <Route exact path="/transactions">
        <AdminTransactions/>
      </Route>

      <Route exact path="/settings">
        <CompanySettings/>
      </Route>

      <Route exact path="/Regions">
        <CompanyRegions/>
      </Route>

      <Route exact path="/supplier/:supplierId">
        <SingleSupplier/>
      </Route>

      <Route>
        <NotFound />
      </Route>
      
    </Switch>
  );
}