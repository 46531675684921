import "./CompanyRegions.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EmployeeService from '../Service/EmployeeService';
import CompanyService from '../Service/CompanyService';
import TransactionComponent from './TransactionsComponent';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { TabView, TabPanel } from 'primereact/tabview';
import { useAppContext } from "../libs/contextLib";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import 'react-google-places-autocomplete/dist/index.min.css';

const CompanyRegions = () => {

    let emptyRegion = {
        "id": "",
        "isDeleted": false,
        "location": "",
        "name": "",
        "province": "",
        "country": "",
        "supervisorId": "",
        "supervisor": "",
        "noOfEmployees": 0,
        "noOfDays": 30
    }

    const [regions, setRegions] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [supervisorList, setSupervisorList] = useState([]);
    const [regionDialog, setRegionDialog] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [redeleteRegionDialog, setDeleteRegionDialog] = useState(false);
    const [region, setRegion] = useState(emptyRegion);
    const [selectedRegions, setSelectedRegions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResetButton, setIsLoadingResetButton] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isUpdateRegion, setIsUpdateRegion] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [userTypes, setUserTypes] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [initialLoad, setInitialLoad] = useState(false);
    const regionsLoading = new Array(3);
    const history = useHistory();
    // const employeeService = new EmployeeService();
    const companyService = new CompanyService();
    const [config, setConfig] = useState(null);
    const { userHasAuthenticated, userIsCompanyAdmin, isUserCompanyCashier, userIsCompanyCashier, isUserCompanySupervisor, userIsCompanySupervisor } = useAppContext();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setInitialLoad(true);

        if (!userHasAuthenticated) {
            userHasAuthenticated(false);
            //Clear Local storage
            userIsCompanyAdmin(false);
            userIsCompanyCashier(false);
            userIsCompanySupervisor(false);
            window.localStorage.clear();

            //Redirect to login
            history.push("/login");
        }
        //Get UserID 
        const user = JSON.parse(window.localStorage.getItem("user"));

        if (user == null || user.userType !== '3900') {
            history.push("/NotFound");
            return;
        }
        else {
            setLoggedInUser(user);

            let _config = { headers: { Authorization: `Bearer ${user.token}` } };
            setConfig({
                ..._config
            });

            //Get All Company Regions 
            companyService.getAllCompanyRegions(_config).then(data => {
                setRegions(data);
                setInitialLoad(false);
            }).catch(function (error) {
                console.log(error.response);
                setInitialLoad(false);

                if(error?.response?.status === 401)
                {
                   userHasAuthenticated(false);
                   //Clear Local storage
                   userIsCompanyAdmin(false);
                   userIsCompanyCashier(false);
                   userIsCompanySupervisor(false);
                   window.localStorage.clear();
               
                   //Redirect to login
                    history.push("/login");
                } 
            });

            //Get All Company Employees 
            setIsLoading(true);
            companyService.getAllCompanyUsers(_config).then(data => {
                // setEmployees(data);
                let _employeeList = [];
                data.forEach(element => {
                    _employeeList.push({ name: (element.firstName + ' ' + element.lastName), id:element.id });
                });
    
                setSupervisorList(_employeeList);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error.response);
                setIsLoading(false);
            });
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleGoogleMapsSearch(result) {
        if (result !== null) {
            //Break down results 
            region.location = result.label;
            let array = result.label.split(',');
            region.country = array[array.length -1]
        }
    }

    const openNew = () => {
        setRegion(emptyRegion);
        setSubmitted(false);
        setRegionDialog(true);
        setIsUpdateRegion(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setRegionDialog(false);
    }

    const hideDeleteRegionDialog = () => {
        setDeleteRegionDialog(false);
    }

    const saveRegion = () => {
        setSubmitted(true);
        setIsLoading(true);

        // region.supervisorId = region.supervisor.id;

        if (region.name.trim()) {
            let _regions = [...regions];
            let _region = { ...region };

            if (region.id) {
                //Existing region that need to be updated 
                const index = findIndexById(region.id);
                _region.supervisorId = _region.supervisor.id;
                _regions[index] = _region;
                companyService.updateCompanyRegion(_region, config).then(data => {
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Region Added', life: 3000 });
                    setRegions(_regions);
                    setRegion(emptyRegion);

                }).catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Failed to add region', detail: error?.response?.data, life: 15000 });
                });
            }
            else {
                _region.supervisorId = _region.supervisor.id;
                //New user to be added 
                companyService.addCompanyRegion(_region, config).then(data => {
                    setIsLoading(false);
                    _regions.push(data);
                    setRegions(_regions);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Region Added', life: 3000 });
                    setRegion(emptyRegion);

                }).catch(function (error) {
                    toast.current.show({ severity: 'error', summary: 'Failed to add region', detail: error?.response?.data, life: 15000 });
                    setIsLoading(false);
                });
            }
        }

        setRegionDialog(false);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < regions.length; i++) {
            if (regions[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const editRegion = (region) => {
        
        let _employees = supervisorList?.filter(val => val.id === region.supervisorId);
        region.supervisor = _employees[0];
        setIsUpdateRegion(true);
        setRegion({ ...region });
        setRegionDialog(true);
    }

    const confirmDeleteRegion = (region) => {
        setRegion(region);
        setDeleteRegionDialog(true);
    }

    const deleteRegion = () => {
        let _regions = [...regions];
        let _region = { ...region };
        _region.isDeleted = true;

        const index = findIndexById(region.id);
        _regions[index] = _region;
        companyService.updateCompanyRegion(_region, config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Region Deactivated on the system', life: 8000 });
            setRegions(_regions);
            setRegion(emptyRegion);
        }).catch(function (error) {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed to Deactivate region', detail: error.response.data, life: 3000 });
        });

        setDeleteRegionDialog(false);
    }

    const reActivateRegion = (regionToActivate) => {
        let _regions = [...regions];
        let _region = { ...regionToActivate };
        _region.isDeleted = false;

        const index = findIndexById(regionToActivate.id);
        _regions[index] = _region;
        companyService.updateCompanyRegion(_region, config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Region Re-Activated on the system', life: 8000 });
            setRegions(_regions);
            setRegion(emptyRegion);
        }).catch(function (error) {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed to Re-Activate region', detail: error.response.data, life: 3000 });
        });

        setDeleteRegionDialog(false);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const headerTabList = (
        <span >
            <i className="pi pi-list" style={{ marginRight: "10px" }} />
            <label style={{ cursor: "pointer" }}>Region List </label>
        </span>
    );

    const headerTabReports = (
        <span>
            <i className="pi pi-chart-line" style={{ marginRight: "10px" }} />
            <label style={{ cursor: "pointer" }}>Region Reports </label>
        </span>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _region = { ...region };
        _region[`${name}`] = val;

        setRegion(_region);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add New Region" icon="pi pi-plus" className="p-button-success p-button-outlined p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-outlined" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const addressBodyTemplate = (rowData) => {
        return <span >{rowData.location == null ? "" : rowData.location.length > 35 ? rowData.location.substring(0, 35) + "..." : rowData.location}</span>;
    }

    const supervisorBodyTemplate = (rowData) => {
        let _supervisor = supervisorList.filter(val => val.id === rowData.supervisorId);
        return <span >{_supervisor[0]?.name} </span>;
    }

    const statusBodyTemplate = (rowData) => {
        return  <span >{rowData.isDeleted ? 
            <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} tooltip={rowData.daysLeft + " days left"} label="Inactive" className="p-button-rounded p-button-outlined p-button-danger" /></Tag> 
            : <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} label="Active" className="p-button-rounded p-button-outlined p-button-success" /></Tag>}</span>;
    }

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                {rowData.isDeleted ?
                    <Button icon="pi pi-check-circle" className="p-button-rounded p-button-outlined p-button-success" onClick={() => reActivateRegion(rowData)} />
                    :
                    rowData.name !== "DEFAULT" && <div>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-warning p-mr-2" onClick={() => editRegion(rowData)} tooltip="edit region" />
                        {rowData.id !== loggedInUser.userID ? <Button icon="pi pi-ban" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => confirmDeleteRegion(rowData)} tooltip="delete region" /> : <Button icon="pi pi-ban" disabled className="p-button-rounded " tooltip="Reactivate region" />}
                    </div>
                }

            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Regions</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const regionDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text p-button-outlined" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text p-button-outlined" onClick={saveRegion} />
        </React.Fragment>
    );
    const deleteregionDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text p-button-outlined" onClick={hideDeleteRegionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text p-button-outlined" onClick={deleteRegion} />
        </React.Fragment>
    );

    return (
        <div className="datatable-crud-products">
            <Toast ref={toast} />

            <div className="card">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header={headerTabList}>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        {!initialLoad
                            ?
                            <DataTable ref={dt} value={regions} selection={selectedRegions} onSelectionChange={(e) => setSelectedRegions(e.value)}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} regions"
                                globalFilter={globalFilter}
                                header={header}>

                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="name" header="Name" sortable></Column>
                                <Column field="province" header="Province" sortable></Column>
                                <Column field="country" header="Country" sortable></Column>
                                <Column field="supervisorId" header="Supervisor Name" body={supervisorBodyTemplate} sortable></Column>
                                <Column field="noOfEmployees" header="No Of Employees" sortable></Column>
                                <Column field="location" header="Address" body={addressBodyTemplate} ></Column>
                                <Column field="isDeleted" header="Status" body={statusBodyTemplate} sortable></Column>
                                <Column header="Action" body={actionBodyTemplate}></Column>
                            </DataTable>
                            :
                            <DataTable value={regionsLoading} className="p-datatable-striped">
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="name" header="Name" body={bodyTemplate} sortable></Column>
                                <Column field="supervisorId" header="Supervisor Name" body={bodyTemplate} sortable></Column>
                                <Column field="province" header="Province" body={bodyTemplate} sortable></Column>
                                <Column field="country" header="Country" body={bodyTemplate} sortable></Column>
                                <Column field="location" header="Address" body={bodyTemplate} ></Column>
                                <Column field="noOfEmployees" header="No of employees" body={bodyTemplate} sortable></Column>
                                <Column field="isDeleted" header="Status" body={bodyTemplate} sortable></Column>
                                <Column header="Action" body={bodyTemplate}></Column>
                            </DataTable>
                        }
                        {isLoading ? <ProgressSpinner /> : ""}
                    </TabPanel>
                    <TabPanel header={headerTabReports}>
                        {/* Reports based on the following 
                            1. Revenue Per region
                            2. Tax per region 
                            3. No of employees per region 
                            4. Orders per region 
                            5. Suppliers per region 
                            6. Products per region 
                            7. Invoices per region 
                            8. Expenses per region  */}
                    </TabPanel>
                </TabView>
            </div>

            <Dialog visible={regionDialog} style={{ width: '450px', textAlign: 'left' }} header="Region Details" modal className="p-fluid" footer={regionDialogFooter} onHide={hideDialog}>

                <div className="p-field"> {/*TODO: */}
                    <label htmlFor="regionSupervisor">Region Supervisor</label>
                    {!isLoading && <Dropdown id="regionSupervisor" value={region.supervisor} options={supervisorList} onChange={(e) => onInputChange(e, 'supervisor')} optionLabel="name" placeholder={region.supervisor?  region.supervisor.name : "Select Supervisor"} required autoFocus className={classNames({ 'p-invalid': submitted && !region.supervisor })} />}
                </div>
                
                <div className="p-field"> {/*TODO: */}
                    {isLoading && <ProgressSpinner style={{ width: "30px", height: "30px" }} />}
                </div>

                <div className="p-field">
                    <label htmlFor="regionName">Region Name</label>
                    <InputText id="regionName" value={region.name} onChange={(e) => onInputChange(e, 'name')} required className={classNames({ 'p-invalid': submitted && !region.name })} />
                    {submitted && !region.name && <small className="p-error">Region Name is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="province">Province</label>
                    <InputText id="province" value={region.province} onChange={(e) => onInputChange(e, 'province')} required className={classNames({ 'p-invalid': submitted && !region.province })} />
                    {submitted && !region.province && <small className="p-error">Province is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="location">Region's Address</label>
                    <GooglePlacesAutocomplete
                    apiKey="AIzaSyAGaZhlnZWzKgdDclTj-68KUEXk8ibj3VQ"  
                    selectProps={{
                            placeholder: 'e.g. 1 Pretoria North street',
                            menuPlacement:'top',
                            defaultInputValue:region.location,
                            onChange : handleGoogleMapsSearch
                        }}
                        />
                </div>
            </Dialog>

            <Dialog visible={redeleteRegionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteregionDialogFooter} onHide={hideDeleteRegionDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {region && <span>Are you sure you want to Deactivate <b>{region.firstName}'s account</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}
export default CompanyRegions