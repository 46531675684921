import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useAppContext } from "../libs/contextLib";
import EmployeeService from '../Service/EmployeeService';
import { useHistory } from "react-router-dom";
import './SupplierOrders.css';

const SupplierOrders = ({supplierName}) => {
    const [customers, setCustomers] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const { userHasAuthenticated, userIsCompanyAdmin, isUserCompanyCashier, userIsCompanyCashier, isUserCompanySupervisor, userIsCompanySupervisor } = useAppContext();
    const employeeService = new EmployeeService();
    const [config, setConfig] = useState(null);
    const history = useHistory();
    const dt = useRef(null);

    const regions = [
        { name: "Johannesburg", code: 'JHB' },
        { name: "Polokwane", code: 'PLK' },
        { name: "Pretoria", code: 'PTA' },
        { name: "Motlolo", code: 'PLK-M' },
    ];

    const statuses = [
        'rejected', 'confirmed', 'new', 'cancelled', 'pending', 'complete',
    ];

    useEffect(() => {

        //Get UserID 
        const user = JSON.parse(window.localStorage.getItem("user"));

        if (user != null) {
            setLoggedInUser(user);

            let _config = { headers: { Authorization: `Bearer ${user.token}` } };
            setConfig({
                ..._config
            });

            //Get employees 
            employeeService.getAllCompanyUsers(_config).then(data => {

                let _employees = [];

                data.forEach(element => {
                    _employees.push({fullName: element.firstName +' ' + element.lastName});
                });

                setEmployees(_employees);
            }).catch(function (error) {
                if (error?.response?.status === 401) {
                    userHasAuthenticated(false);
                    //Clear Local storage
                    userIsCompanyAdmin(false);
                    userIsCompanyCashier(false);
                    userIsCompanySupervisor(false);
                    window.localStorage.clear();

                    //Redirect to login
                    history.push("/login");
                }
            });
        }
        // customerService.getCustomersLarge().then(data => setCustomers(data));
        let data = [
            {
                "id": 1000,
                "name": "James Butt",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Benton, John B Jr",
                "date": "2015-09-13",
                "status": "rejected",
                "activity": 100,
                "employee": {
                    "fullName": "Lekwadi",
                    "image": "ionibowcher.png"
                }
            },
            {
                "id": 1001,
                "name": "Josephine Darakjy",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Chanay, Jeffrey A Esq",
                "date": "2019-02-09",
                "status": "complete",
                "activity": 100,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "amyelsner.png"
                }
            },
            {
                "id": 1002,
                "name": "Art Venere",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Chemel, James L Cpa",
                "date": "2017-05-13",
                "status": "confirmed",
                "activity": 75,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "asiyajavayant.png"
                }
            },
            {
                "id": 1003,
                "name": "Lenna Paprocki",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Feltz Printing Service",
                "date": "2020-09-15",
                "status": "new",
                "activity": 0,
                "employee": {
                    "fullName": "Xuxue Feng",
                    "image": "xuxuefeng.png"
                }
            },
            {
                "id": 1004,
                "name": "Donette Foller",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Printing Dimensions",
                "date": "2016-05-20",
                "status": "complete",
                "activity": 100,
                "employee": {
                    "fullName": "Asiya Javayant",
                    "image": "asiyajavayant.png"
                }
            },
            {
                "id": 1005,
                "name": "Simona Morasca",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Chapman, Ross E Esq",
                "date": "2018-02-16",
                "status": "confirmed",
                "activity": 75,
                "employee": {
                    "fullName": "Ivan Magalhaes",
                    "image": "ivanmagalhaes.png"
                }
            },
            {
                "id": 1006,
                "name": "Mitsue Tollner",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Morlong Associates",
                "date": "2018-02-19",
                "status": "pending",
                "activity": 25,
                "employee": {
                    "fullName": "Ivan Magalhaes",
                    "image": "ivanmagalhaes.png"
                }
            },
            {
                "id": 1007,
                "name": "Leota Dilliard",
                "region": {
                    "name": "Pretoria",
                    "code": "PTA"
                },
                "company": "Commercial Press",
                "date": "2019-08-13",
                "status": "pending",
                "activity": 25,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "onyamalimba.png"
                }
            },
            {
                "id": 1008,
                "name": "Sage Wieser",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Truhlar And Truhlar Attys",
                "date": "2018-11-21",
                "status": "rejected",
                "activity": 100,
                "employee": {
                    "fullName": "Ivan Magalhaes",
                    "image": "ivanmagalhaes.png"
                }
            },
            {
                "id": 1009,
                "name": "Kris Marrier",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "King, Christopher A Esq",
                "date": "2015-07-07",
                "status": "complete",
                "activity": 100,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "onyamalimba.png"
                }
            },
            {
                "id": 1010,
                "name": "Minna Amigon",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Dorl, James J Esq",
                "date": "2018-11-07",
                "status": "confirmed",
                "activity": 75,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "annafali.png"
                }
            },
            {
                "id": 1011,
                "name": "Abel Maclead",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Rangoni Of Florence",
                "date": "2017-03-11",
                "status": "qualified",
                "activity": 87,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "bernardodominic.png"
                }
            },
            {
                "id": 1012,
                "name": "Kiley Caldarera",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Feiner Bros",
                "date": "2015-10-20",
                "status": "rejected",
                "activity": 100,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "onyamalimba.png"
                }
            },
            {
                "id": 1013,
                "name": "Graciela Ruta",
                "region": {
                    "name": "Johannesburg",
                    "code": "JHB"
                },
                "company": "Buckley Miller & Wright",
                "date": "2016-07-25",
                "status": "cancelled",
                "activity": 59,
                "employee": {
                    "fullName": "Thabang Lekwadi",
                    "image": "amyelsner.png"
                }
            },
            {
                "id": 1014,
                "name": "Cammy Albares",
                "region": {
                    "name": "Polokwane",
                    "code": "PLK"
                },
                "company": "Rousseaux, Michael Esq",
                "date": "2019-06-25",
                "status": "new",
                "activity": 0,
                "employee": {
                    "fullName": "Asiya Javayant",
                    "image": "asiyajavayant.png"
                }
            },
            {
                "id": 1015,
                "name": "Mattie Poquette",
                "region": {
                    "name": "Motlolo",
                    "code": "PLK-M"
                },
                "company": "Century Communications",
                "date": "2017-12-12",
                "status": "cancelled",
                "activity": 52,
                "employee": {
                    "fullName": "Anna Fali",
                    "image": "annafali.png"
                }
            }];

        setCustomers(data);


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === formatDate(filter);
    }

    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }

    const onEmployeesChange = (e) => {
        dt.current.filter(e.value, 'employee.fullName', 'in');
        setSelectedEmployee(e.value);
    }

    const onRegionsChange = (e) => {
        dt.current.filter(e.value, 'region.name', 'in');
        setSelectedRegion(e.value);
    }

    const onDateChange = (e) => {
        dt.current.filter(e.value, 'date', 'custom');
        setSelectedDate(e.value);
    }

    const onStatusChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Order Number</span>
                ON{rowData.id}
            </React.Fragment>
        );
    }

    const RegionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Region</span>
                <span className="image-text">{rowData.region.name}</span>
            </React.Fragment>
        );
    }

    const employeeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Employee</span>
                <img alt={rowData.employee.fullName} src={`showcase/demo/images/avatar/${rowData.employee.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="image-text">{rowData.employee.fullName}</span>
            </React.Fragment>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Date</span>
                <span>{rowData.date}</span>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>
            </React.Fragment>
        );
    }

    const activityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Progress</span>
                <ProgressBar value={rowData.activity} color={rowData.status == 'complete' ? 'green' : ''} />
            </React.Fragment>
        );
    }

    const employeesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <img alt={option.name} src={`showcase/demo/images/avatar/${option.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="image-text">{option.fullName}</span>
            </div>
        );
    }

    const regionItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.name}</span>
            </div>
        );
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const header = (
        <div className="table-header">
            {supplierName} - Orders
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const regionFilter = <MultiSelect value={selectedRegion} options={regions} itemTemplate={regionItemTemplate} onChange={onRegionsChange} optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter" />;
    const employeeFilter = <MultiSelect value={selectedEmployee} options={employees} itemTemplate={employeesItemTemplate} onChange={onEmployeesChange} optionLabel="fullName" optionValue="fullName" placeholder="All" className="p-column-filter" />;
    const dateFilter = <Calendar value={selectedDate} onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="Order Date" />;
    const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;

    return (
        <div className="datatable-filter-demo">
            <div className="card">
                <DataTable ref={dt} value={customers} paginator rows={7} reorderableColumns
                    header={header} className="p-datatable-customers"
                    globalFilter={globalFilter} emptyMessage="No orders found.">
                    <Column field="name" header="Order#" body={nameBodyTemplate} filter filterPlaceholder="Search by Order#" />
                    <Column field="region" filterField="region.name" header="Region" body={RegionBodyTemplate} filter filterElement={regionFilter} />
                    <Column field="employee.fullName" header="Employee" body={employeeBodyTemplate} filter filterElement={employeeFilter} />
                    <Column field="date" header="Order Date" body={dateBodyTemplate} filter filterElement={dateFilter} filterFunction={filterDate} />
                    <Column field="status" header="Status" body={statusBodyTemplate} filter filterElement={statusFilter} />
                    <Column field="activity" header="Progress" body={activityBodyTemplate} filter filterPlaceholder="Minimum" filterMatchMode="gte" />
                </DataTable>
            </div>
        </div>
    );
}

export default SupplierOrders