import axios from 'axios';

//Printer service url
const apiBaseUrl = "https://localhost:5201/PrintReciept/"

export default class PrinterService {
    async sendToPrinter(payload){
        return await axios.post(`${apiBaseUrl}SendToPrinter`,payload).then(res => res.data);
    }

    async testConnection(){
        return await axios.get(`${apiBaseUrl}`).then(res => res.data);
    }
}
    