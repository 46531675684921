import "./Cashier.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProductService from '../Service/ProductService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { FileUploadService } from '../Service/FileUploadService';
import { Card } from 'primereact/card';
import { SelectButton } from 'primereact/selectbutton';
import TransactionService from '../Service/TransactionService';
import UserService from "../Service/EmployeeService";
import PrinterService from "../Service/PrinterService";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";

const Cashier = () => {
    let emptyProduct = {
        id: null,
        productCode: '',
        barCode: '',
        discount: '',
        name: '',
        image: null,
        price: 0,
        quantity: 0,
    };

    const paymentOptions = [
        { name: 'CASH', value: 1111 },
        { name: 'CARD', value: 2111 },
    ];
    const history = useHistory();
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const [paymentOptionsDialog, setPaymentOptionsDialog] = useState(false);
    const [returnProductsPaymentOptionsDialog, setReturnProductsPaymentOptionsDialog] = useState(false);
    const [isCardPaynemt, setIsCardPaynemt] = useState(false);
    const [isCashPaynemt, setIsCashPaynemt] = useState(false);
    const [products, setProducts] = useState([]);
    const [companyProducts, setCompanyProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [barCode, setBarCode] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [customerFullName, setCustomerFullName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [VATRate, setVATRate] = useState(15);//15%
    const [totalTractionCost, setTotalTractionCost] = useState(0.00);//15%
    const [isPaid, setIsPaid] = useState(false);
    const [isTransactionRefundDetails, setIsTransactionRefundDetails] = useState(false);
    const [isPayout, setIsPayout] = useState(false);
    const [transactionCashReceived, setTransactionCashReceived] = useState(0);
    const [transactionChange, setTransactionChange] = useState(0);
    const [isTransactionChangeDialog, setIsTransactionChangeDialog] = useState(false);
    const [isReturnedProduct, setIsReturnedProduct] = useState(false);
    const [isTransactionProcessing, setIsTransactionProcessing] = useState(false);
    const [totalExcVAT, setTotalExcVAT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [config, setConfig] = useState(null);
    const productService = new ProductService();
    const fileUploadService = new FileUploadService();
    const transactionService = new TransactionService();
    const employeeService = new UserService();
    const printerService = new PrinterService();
    const [cashierInfo, setCashierInfo] = useState(null);

    const { userHasAuthenticated, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor} = useAppContext();

    let inputNumberRef = React.createRef(null);

    useEffect(() => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        
        console.log("Initial Load");
        onLoad(user);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    function onLoad(user) {
        if (!user)
        user = loggedInUser;
        
        setLoggedInUser(user);
        let _config = {headers: { Authorization: `Bearer ${user.token}` }};
            setConfig({
                ..._config
            });

        productService.getAllCashierProducts(_config).then(data => {
            setCompanyProducts(data);
            // calculateTotalTransaction(data);
        }).catch(function (error) {
            console.log(error.response);

            if(error?.response?.status === 401)
            {
               userHasAuthenticated(false);
               //Clear Local storage
               userIsCompanyAdmin(false);
               userIsCompanyCashier(false);
               userIsCompanySupervisor(false);
               window.localStorage.clear();
           
               //Redirect to login
                history.push("/login");
            } 
        });

        employeeService.getEmployee(user.userID,_config).then(data =>{
            setCashierInfo(data);
        });

    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-za', { style: 'currency', currency: 'ZAR' });
    }

    const hideDialog = () => {
        setProduct(emptyProduct);
        setBarCode('');
        setProductDialog(false);
    }

    const hidePaymentMethodDialog = () => {
        setPaymentOptionsDialog(false);
        inputNumberRef.current.element.focus();

        if (isPaid)
            handleTransactionChange();
    }

    const hideReturnedProductsPaymentMethodDialog = () => {
        setReturnProductsPaymentOptionsDialog(false);
        setIsTransactionRefundDetails(false);
        inputNumberRef.current.element.focus();

        if (isPayout)
            handleTransactionRefund();
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const automaticSaveProduct = (productScanned, existingProductScanned) => {

        if (productScanned.name.trim()) {
            let _products = [...products];
            let _product = { ...productScanned };

            if (existingProductScanned) {
                //Product Exist in the cart therefore just add quantity
                const index = findIndexByBarcodeInExistingCart(productScanned.barCode);
                _product.quantity++;//Incrase quantity by 1
                _products[index] = _product;
                setProducts(_products);
                setProduct(emptyProduct);
                setBarCode(null);
                calculateTotalTransaction(_products);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Added', life: 3000 });
            }
            else {
                //New product to the cart 
                _product.quantity++;
                _products.push(_product);//add to the list 
                setProducts(_products);
                setProduct(emptyProduct);
                setBarCode(null)
                calculateTotalTransaction(_products);
            }

            setProductDialog(false);
        }
        else {
            
                toast.current.show({ severity: 'warn', summary: !isReturnedProduct ? 'Cannot sell product!': 'Cannot refund product!', detail: 'Product Not in the system', life: 8000 });
                
            console.log("line: 158")
            setBarCode(null);
            setProductBarcodeFromScanner('');
            setProductDialog(false);
        }
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const paymentDialog = (product) => {
        //Set payment Dialog Open
        setPaymentOptionsDialog(true);
    }

    const returnProductspaymentDialog = (product) => {
        //Set payment Dialog Open
        setReturnProductsPaymentOptionsDialog(true);
    }

    const deleteProduct = () => {
        let _products = products.filter(val => val !== product);
        setProducts(_products);
        setProduct(emptyProduct);
        setDeleteProductDialog(false);
        calculateTotalTransaction(_products);
    }

    const findIndexByBarcodeInExistingCart = (barCode) => {
        let index = -1;
        for (let i = 0; i < products?.length; i++) {
            if (products[i].barCode === barCode) {
                index = i;
                break;
            }
        }
        return index;
    }

    const findIndexByBarcodeInCompanyProducts = (barCode) => {
        let index = -1;
        for (let i = 0; i < companyProducts?.length; i++) {
            if (companyProducts[i].barCode === barCode) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.exportFilename = Date.now();
        dt.current.exportCSV();
    }
    const header = (
        <div className="table-header">
            <h5 className="p-m-0">{isReturnedProduct ? "Returned Items" : "Transaction Items"}</h5>
        </div>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {
                    !isReturnedProduct
                        ?
                        <Button label="Return Products" icon="pi pi-backward" className="p-button-danger p-mr-2" onClick={() => setIsReturnedProduct(true)} />
                        :
                        <Button label="New Transaction" icon="" className="p-button-success p-mr-2" onClick={() => setIsReturnedProduct(false)} />
                }
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Email Receipt" disabled={!isPaid} icon="pi pi-envelope" className="p-button-help" onClick={exportCSV} />
                <Button label="Print Receipt" disabled={!isPaid} icon="pi pi-print" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const imageBodyTemplate = (rowData) => {
        return <img height="50px" width="50px" src={rowData.image || fileUploadService.processImageLocation(null)} alt={rowData.image} />
    }

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(calculatePriceExcludingVAT(rowData.price) * rowData.quantity);
    }

    function calculatePriceExcludingVAT(priceIncVAT) {
        let vat = 1 + VATRate / 100;
        return priceIncVAT / vat;
    }

    function calculateVAT(priceIncVAT) {
        let vat = calculatePriceExcludingVAT(priceIncVAT) * VATRate / 100;

        return vat;
    }

    const quantityBodyTemplate = (rowData) => {
        return rowData.quantity;
    }

    const vatBodyTemplate = (rowData) => {
        return formatCurrency(calculateVAT(rowData.price) * rowData.quantity);
    }

    const totalBodyTemplate = (rowData) => {
        return <b style={{ color: "green" }}>{formatCurrency(rowData.price * rowData.quantity)}</b>;
    }

    const discountBodyTemplate = (rowData) => {
        return "0.00%";
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" label="Remove" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const calculateTotalTransaction = (products) => {
        let totalPriceExcVat = 0;
        let totalVat = 0;
        let totalTransactionPrice = 0;
        let totalQuantity = 0;

        if (products)
            products.forEach(product => {
                totalTransactionPrice += product.price * product.quantity;
                totalQuantity += product.quantity;
                totalPriceExcVat += calculatePriceExcludingVAT(product.price) * product.quantity;
                totalVat += calculateVAT(product.price) * product.quantity;
            });

        setTotalExcVAT(totalPriceExcVat);
        setTotalQuantity(totalQuantity);
        setTotalTractionCost(totalTransactionPrice);
        setTotalVAT(totalVat);

    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    function setProductBarcodeFromScanner(value) {
        setBarCode(value);
        
        //Check if product exist in the list of products 
        if (value.length >= 8) {
            let index = findIndexByBarcodeInExistingCart(value);
            let isFound = false;
            if (index !== -1) {
                automaticSaveProduct(products[index], true);
                isFound = true;
            }
            else {
                let productIndex = findIndexByBarcodeInCompanyProducts(value);

                if (productIndex !== -1) {
                    automaticSaveProduct(companyProducts[productIndex], false);
                    isFound = true;
                }
            }

            if (value.length >= 13 && !isFound) {
                setBarCode('');
                setProductBarcodeFromScanner('');

                console.log("line: 338")
                toast.current.show({ severity: 'warn', summary:  !isReturnedProduct ? 'Cannot sell product!': 'Cannot refund product!', detail: 'Product Not in the system', life: 8000 });
            }
        }
    }

    const paymentTemplate = (option) => {

        return (
            <React.Fragment>
                <Card title={option.name} style={{ width: '25rem' }}>
                    <i className={option.value === 1111 ? "pi pi-money-bill" : "pi pi-credit-card"} style={{ 'fontSize': '5em' }}></i>
                </Card>
            </React.Fragment>)
    }

    const configurePayment = (value) => {

        if (value === 1111) {
            setIsCashPaynemt(true);
            setIsCardPaynemt(false);
        }
        else if (value === 2111) {
            setIsCardPaynemt(true);
            setIsCashPaynemt(false);
        }
        else {
            setIsCashPaynemt(false);
            setIsCardPaynemt(false);
        }

        setSelectedPaymentOption(value);
    }

    const handleCashAccepted = () => {

        setIsTransactionProcessing(true);
        
        if (transactionCashReceived >= totalTractionCost) {

            let transactionItems = [];

            products.forEach(item => {
                transactionItems.push({
                    productBarCode: item.barCode,
                    itemDescription: item.description,
                    itemImage: item.image,
                    itemName: item.name,
                    priceExcVAT: calculatePriceExcludingVAT(item.price),
                    priceIncVAT: item.price,
                    totalPriceIncVAT: item.price * item.quantity,
                    totalDiscountIncVAT: 0,
                    totalVAT: calculateVAT(item.price) * item.quantity,
                    quantitySold: - item.quantity,
                    suppierName: item.supplierName,
                    categoryName: item.categoryName
                });
            });

            let payload = {
                paymentMehod: "CASH",
                amountReceived: transactionCashReceived,
                changedReturned: transactionCashReceived - totalTractionCost,
                totalTransactionPrice: totalTractionCost,
                numberOfItems: - totalQuantity,
                totalVAT: totalVAT,
                customer: {
                    firstName: "Cash Till",
                    lastName: "",
                    number: ""
                },
                cashier: {
                    firstName: cashierInfo.firstName,
                    lastName: cashierInfo.lastName,
                    location: cashierInfo.location,
                    regionName: cashierInfo.location
                },
                transactionItems: transactionItems
            }

            transactionService.postTransaction(payload, config).then(data => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Transaction successful', life: 3000 });
                setIsTransactionChangeDialog(true);
                setTransactionChange(formatCurrency(transactionCashReceived - totalTractionCost));
                setIsPaid(true);
                setIsCardPaynemt(false);
                setIsTransactionProcessing(false);
                
                console.log("Transact",data);

                //Open Drawer and print a receipt 
                printerService.sendToPrinter(payload);

            })
                .catch(function (error) {
                    console.log(error.response);
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: "see logs", life: 3000 });
                    setIsTransactionProcessing(false);
                });
        }
        else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Cash received is short of ' + formatCurrency(totalTractionCost - transactionCashReceived), life: 3000 });
                setIsTransactionProcessing(false);
            }
    }

    const handleCashPayout = () => {

        // setReturnProductsPaymentOptionsDialog(false);
        setIsTransactionProcessing(true);

        if (totalTractionCost) {

            let transactionItems = [];

            products.forEach(item => {
                transactionItems.push({
                    productBarCode: item.barCode,
                    itemDescription: item.description,
                    itemImage: item.image,
                    itemName: item.name,
                    priceExcVAT: - calculatePriceExcludingVAT(item.price),
                    priceIncVAT: - item.price,
                    totalPriceIncVAT: -(item.price * item.quantity),
                    totalDiscountIncVAT: 0,
                    totalVAT: - (calculateVAT(item.price) * item.quantity),
                    quantitySold: item.quantity,
                    suppierName: item.supplierName,
                    categoryName: item.categoryName
                });
            });

            let payload = {
                paymentMehod: "CASH",
                amountReceived: 0,
                changedReturned: 0,
                totalTransactionPrice: -totalTractionCost,
                numberOfItems: totalQuantity,
                totalVAT: -totalVAT,
                customer: {
                    firstName: customerFullName.split(' ')[0],
                    lastName: customerFullName.split(' ')[1],
                    number: customerNumber
                },
                transactionItems: transactionItems
            }

            transactionService.postTransaction(payload,config).then(_ => {
                setIsTransactionRefundDetails(true);
                setIsTransactionProcessing(false);
                setIsPayout(true);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Refund successful', life: 5000 });     
            })
            .catch(function (error) {      
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Failed', detail: "Refund failed", life: 5000 });
                setIsTransactionProcessing(false);
              });
        }
        else
            console.log("Invalid Transaction price");
    }

    const handleTransactionChange = () => {
        //Disable everything
        setIsTransactionChangeDialog(false);
        setPaymentOptionsDialog(false);
        setProducts([]);
        inputNumberRef.current.element.focus();
        setIsCashPaynemt(false);
        setIsCardPaynemt(false);
        setSelectedPaymentOption(true);
        setTransactionCashReceived(0);
        setIsPaid(false);
        setReturnProductsPaymentOptionsDialog(false);
    }

    const handleTransactionRefund = () => {
        setIsTransactionRefundDetails(false);
        setReturnProductsPaymentOptionsDialog(false);
        setCustomerFullName('');
        setCustomerNumber(0);
        setIsPaid(false);
        setPaymentOptionsDialog(false);
        setProducts([]);
        inputNumberRef.current.element.focus();
        setReturnProductsPaymentOptionsDialog(false);
        setIsReturnedProduct(false);
    }

    const headerTransactionType = () => {
        return (
            <div>
                {
                    !isReturnedProduct
                        ?
                        <h3 style={{ color: 'green' }}>Scan product to start transaction</h3>
                        :
                        <h3 style={{ color: 'red' }}>Scan products to be returned</h3>
                }
            </div>
        )
    }

    return (
        <div className="datatable-crud-products">
            <Toast ref={toast} />

            <div className="">
                <div className="card">
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {!products?.length > 0
                        ?
                        <> </>
                        : <div>
                            <DataTable className="p-datatable-striped" ref={dt} value={products} header={header} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} dataKey="id">
                                <Column headerStyle={{ width: '3rem' }}></Column>
                                <Column field="productCode" header="Code" ></Column>
                                <Column field="name" header="Name" ></Column>
                                <Column header="Image" body={imageBodyTemplate}></Column>
                                <Column field="price" header="Price Exc VAT" body={priceBodyTemplate} ></Column>
                                <Column field="VAT" header={"VAT(" + VATRate + "%)"} body={vatBodyTemplate} ></Column>
                                <Column field="quantity" header="Quantity" body={quantityBodyTemplate}></Column>
                                <Column field="discount" header="Discount" body={discountBodyTemplate}></Column>
                                <Column field="total" header={<h4>Total</h4>} body={totalBodyTemplate} ></Column>
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            <DataTable value={[]}>
                                <Column headerStyle={{ width: '3rem' }}></Column>
                                <Column header={<h3>TOTAL</h3>} ></Column>
                                <Column ></Column>
                                <Column></Column>
                                <Column header={<h5 >{formatCurrency(totalExcVAT)}</h5>}></Column>
                                <Column header={<h5>{formatCurrency(totalVAT)}</h5>}></Column>
                                <Column header={<h5>{totalQuantity}</h5>} ></Column>
                                <Column></Column>
                                <Column header={<h3 style={{ color: "green" }}>{formatCurrency(totalTractionCost)}</h3>} ></Column>
                                {
                                    !isReturnedProduct ? <Column header={<Button icon="pi pi-credit-card" label="Pay" className="p-button-rounded p-button-success" onClick={() => paymentDialog()} />}></Column>
                                 : <Column header={<Button icon="pi pi-credit-card" label="Payout Cash" className="p-button-rounded p-button-danger" onClick={() => returnProductspaymentDialog()} />}></Column>}
                            </DataTable>
                        </div>
                    }
                </div>
            </div>

            <Dialog visible={!products?.length > 0} style={{ width: '550px' }} modal={false} header={headerTransactionType} /*header= {!isReturnedProduct ? "Scan product to start transaction" : "Scan products to be returned"}*/ className="p-fluid" onHide={hideDialog}>
                <div className="p-field"> </div>
                {!products?.length > 0
                    ?
                    <img src={process.env.PUBLIC_URL + '/scanProduct.gif'} style={{ marginTop: '-10px' }} width={'509px'} height={'300px'} alt="loading..." />
                    : <></>
                }
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                </div>
            </Dialog>

            <InputText ref={inputNumberRef} id="automaticBarCodeScanning" onBlur={({ target }) => !isCashPaynemt && !returnProductsPaymentOptionsDialog ? target.focus() : ''} style={{ opacity: -1 }} autoComplete="off" value={barCode || ''} onChange={(e) => setProductBarcodeFromScanner(e.target.value)} required autoFocus={true} />

            {/* Payment Dialog */}
            <Dialog visible={paymentOptionsDialog} style={{ width: '750px' }} header="Payment" modal className="p-fluid" onHide={hidePaymentMethodDialog}>

                {!isTransactionChangeDialog && <SelectButton value={selectedPaymentOption} options={paymentOptions} itemTemplate={paymentTemplate} onChange={(e) => configurePayment(e.value)} optionLabel="name" />}

                {
                    isCardPaynemt
                        ?
                        alert("setup speed point for the system")
                        :
                        isCashPaynemt && !isTransactionChangeDialog && <div className="p-grid">

                            <div className="p-col-6 p-offset-3 p-text-light"><h3>ENTER CASH RECEIVED</h3></div>
                            <div className="p-col-6 p-offset-3 p-text-light" >
                                <InputNumber style={{ height: '80px'}} className="cashReceivedFontSize" value={transactionCashReceived} onValueChange={(e) => setTransactionCashReceived(e.value)} mode="currency" currency="ZAR" locale="en-za" />
                            </div>

                            <div className="p-col-6 p-offset-3">
                                {!isTransactionProcessing ? <Button label="Accept cash" className="p-button-success" onClick={handleCashAccepted} /> : <ProgressSpinner />}
                            </div>
                        </div>
                }

                {
                    isTransactionChangeDialog && <div>
                        <div className="p-grid">
                            <div className="p-col-6 p-offset-3 p-text-light"><h1 style={{ fontSize: "100px", fontWeight: 'bolder', textAlign: "center", marginTop: '10px', color: 'green' }}>  Change</h1></div>
                            <div className="p-col-6 p-offset-3 p-text-light" style={{ fontSize: "80px", textAlign: "center", marginTop: '10px', color: 'green' }}>{transactionChange}</div>

                            <div className="p-col-6 p-offset-3">
                                <Button label="Done" className="p-button-warning" onClick={handleTransactionChange} />
                            </div>
                        </div>
                    </div>
                }
            </Dialog>

            {/* Payout returned  products */}
            <Dialog visible={returnProductsPaymentOptionsDialog } style={{ width: '750px' }} header={"Returned Products Payout"} modal className="p-fluid" onHide={hideReturnedProductsPaymentMethodDialog}>
                {
                    !isTransactionRefundDetails && <div className="p-grid" >
                        <div className="p-col-6 p-offset-3" style={{ textAlign: 'left' }}>
                            <label>Customer FullName</label>
                            <InputText className="p-mb-2 p-d-block" value={customerFullName} onChange={e => setCustomerFullName(e.target.value)}/>
                            <label>Customer Contact Number</label>

                            <InputNumber id="withoutgrouping" placeholder={"0123456789"} value={customerNumber} onChange={(e) => setCustomerNumber(e.value)} useGrouping={false} />
                        </div>

                        <div className="p-col-6 p-offset-3 p-text-light" style={{ textAlign: 'left' }}>
                            <InputNumber style={{ height: '80px', fontSize: "100px" }}  disabled className="cashReceivedFontSize" value={totalTractionCost} mode="currency" currency="ZAR" locale="en-za" />
                        </div>

                        <div className="p-col-6 p-offset-3" >
                            {!isTransactionProcessing ? <Button label="Withdraw" disabled={!(customerFullName && customerNumber)} className="p-button-danger" onClick={handleCashPayout} /> : <ProgressSpinner />}
                        </div>
                    </div>
                }

                {
                    isTransactionRefundDetails && <div>
                        <div className="p-grid">
                            <div className="p-col-6 p-offset-3 p-text-light"><h1 style={{ fontSize: "30px", fontWeight: 'bolder', textAlign: "center", marginTop: '10px', color: 'green' }}>Refund {customerFullName}</h1></div>
                            <div className="p-col-6 p-offset-3 p-text-light" style={{ fontSize: "80px", textAlign: "center", marginTop: '10px', color: 'green' }}>{formatCurrency(totalTractionCost)}</div>

                            <div className="p-col-6 p-offset-3">
                                <Button label="Done" className="p-button-warning" onClick={handleTransactionRefund} />
                            </div>
                        </div>
                    </div>
                }
            </Dialog>
        </div>
    );
}
export default Cashier