
import React from 'react';
import { Chart } from 'primereact/chart';


const TransactionReports = () => {

    const multiAxisData = {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [{
            label: 'No of sales',
            fill: true,
            borderColor: 'green',
            yAxisID: 'y-axis-1',
            data: [65, 59, 80, 81, 56, 55, 10,0,0,0,0,0]
        }, {
            label: 'No of returns',
            fill: true,
            borderColor: 'red',
            yAxisID: 'y-axis-2',
            data: [28, 48, 40, 19, 86, 27, 90,0,0,0,0,0]
        }]
    };

    const getLightTheme = () => {
        let multiAxisOptions = {
            responsive: true,
            hoverMode: 'index',
            stacked: false,
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }, {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    }
                }]
            },
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };

        return {
            multiAxisOptions
        }
    }

    const { multiAxisOptions } = getLightTheme();

    //Polar chart 
    const chartData = {
        datasets: [{
            data: [
                11,
                16,
                7,
                3,
                14
            ],
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726",
                "#26C6DA",
                "#7E57C2"
            ],
            label: 'My dataset'
        }],
        labels: [
            "Product 1",
            "Product 2",
            "Product 3",
            "Product 4",
            "Product 5"
        ]
    };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scale: {
            gridLines: {
                color: '#ebedef'
            }
        }
    };

    return (
        <div>
            <h5 style={{textAlign:'left'}}>Product Sales Vs Returns </h5>
            <div className="card p-grid">
                <Chart className="p-col-6" type="line" data={multiAxisData} options={multiAxisOptions} />
                <Chart className="p-col-6" type="polarArea" data={chartData} options={lightOptions} />
            </div>
        </div>
    )
}

export default TransactionReports