import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Toast } from 'primereact/toast';
// import { HelpBlock,FormGroup,FormControl,ControlLabel } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import axios from 'axios';
import "primereact/resources/themes/md-light-indigo/theme.css"
import API_URL from "../Service/ApiUrl";

const apiBaseUrl = API_URL;

const  Login = () => {
  const [email, setEmail] = useState(""); //useState hooks 
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { userHasAuthenticated, userIsCompanyAdmin,isUserCompanyCashier, userIsCompanyCashier,isUserCompanySupervisor, userIsCompanySupervisor} = useAppContext();
  const toast = useRef(null);
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
  
    var payload = {
      username: email,
      password: password
    }

    var url = apiBaseUrl + `Auth/Login/`;

    try{

    axios.post(url,payload)
         .then(function (response) {
          showSuccess();
          setLoggedInUser(response.data);
          setIsLoading(false);
          let loggedInUser = response.data;
          //TODO: Disable this to ensure that SMS face is not passed 
          userHasAuthenticated(true);

          //If is user ADMIN 
          if(loggedInUser.username === response.data.username)
          {
              window.localStorage.setItem("user", JSON.stringify(response.data));
              if(loggedInUser.userType === '3900'){
                userIsCompanyAdmin(true);
                userIsCompanyCashier(false);
                userIsCompanySupervisor(false);
                setIsLoading(false);
                history.push("/Products");
              }
              else if(loggedInUser.userType === '720'){
                userIsCompanyAdmin(false);
                userIsCompanyCashier(true);
                userIsCompanySupervisor(false);
                setIsLoading(false);
                history.push("/Transact");
              }
              else if(loggedInUser.userType === '330'){
                userIsCompanyAdmin(false);
                userIsCompanyCashier(false);
                userIsCompanySupervisor(true);
                setIsLoading(false);
                history.push("/Products");
              }
          }
          else
          {
              history.push("/"); 
          }
          //Till here
        }).catch(function (error) {      
          showError("Could not login");
          console.log(error);
          setIsLoading(false);
        });
  }
  catch(error) {      
    showError("Connection failed");
    console.log(error);
    setIsLoading(false);
  }
}
  
  function showError(message) {
    toast.current.show({severity:'error', summary: 'Login failed', detail:message, life: 7000});
}

function showSuccess() {
  toast.current.show({severity:'success', summary: 'Success', detail:"Welcome ", life: 3000});
}


function renderLogin() {
  return (<form onSubmit={handleSubmit}>
  <FormGroup controlId="email" bsSize="large">
    <ControlLabel>Email</ControlLabel>
    <FormControl
      autoFocus
      type="email"
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
  </FormGroup>
  <FormGroup controlId="password" bsSize="large">
    <ControlLabel>Password</ControlLabel>
    <FormControl
      value={password}
      onChange={e => setPassword(e.target.value)}
      type="password"
    />
  </FormGroup>

  {/* {
    !isLoading ? <Button disabled={!validateForm()} type="submit">Login</Button> : <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
  } */}

  <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
  </LoaderButton>

</form>
  )
}

async function handleConfirmationSubmit(event) {
  event.preventDefault();
  setIsLoading(true);

  try{
    
    var payload = {
        "username" : loggedInUser.username,
        "code": fields.confirmationCode,
      }
    
    var url = apiBaseUrl + 'Auth/Activate?userId='+ loggedInUser.userID;
  
    await axios.post(url,payload)
         .then(function (response) { 

          userHasAuthenticated(true);

          //If is user ADMIN 
          if(loggedInUser.username === response.data.username)
          {
              window.localStorage.setItem("user", JSON.stringify(loggedInUser));
              if(loggedInUser.userType === '3900'){
                userIsCompanyAdmin(true);
                userIsCompanyCashier(false);
                userIsCompanySupervisor(false);
                setIsLoading(false);
                history.push("/Products");
              }
              else if(loggedInUser.userType === '720'){
                userIsCompanyAdmin(false);
                userIsCompanyCashier(true);
                userIsCompanySupervisor(false);
                setIsLoading(false);
                history.push("/Transact");
              }
              else if(loggedInUser.userType === '330'){
                userIsCompanyAdmin(false);
                userIsCompanyCashier(false);
                userIsCompanySupervisor(true);
                setIsLoading(false);
                history.push("/Products");
              }
          }
          else
          {
              history.push("/"); 
          }
         
          }).catch(function (error) {            
            showError(error.response.data);
            setIsLoading(false);
          });
  }
  catch(e){
    setIsLoading(false);
    console.log(e);
    showError("Connection failed");
  }
}

function validateConfirmationForm() {
  return fields.confirmationCode.length === 5;
}

function renderConfirmationForm() {
  return (
    <form onSubmit={handleConfirmationSubmit}>
      <FormGroup controlId="confirmationCode" bsSize="large">
      <ControlLabel>Please check your phone for the code.</ControlLabel>
        <FormControl
          autoFocus
          type="text"
          onChange={handleFieldChange}
          value={fields.confirmationCode}
        />
      
      </FormGroup>
      
      <LoaderButton
        block
        type="submit"
        bsSize="large"
        isLoading={isLoading}
        disabled={!validateConfirmationForm()}
      >
        Verify
      </LoaderButton>
    </form>
  );
}

  return (
    <div className="Login">
      {/* { loggedInUser != null ? renderConfirmationForm() :renderLogin()} Enable for SMS*/} 
      {renderLogin()}
      <Toast ref={toast}/>
     </div>
  );
}

export default Login