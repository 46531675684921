import "./Employees.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EmployeeService from '../Service/EmployeeService';
import CompanyService from '../Service/CompanyService';
import TransactionComponent from './TransactionsComponent';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { TabView, TabPanel } from 'primereact/tabview';
import { useAppContext } from "../libs/contextLib";

const Employees = () => {

    let emptyEmployee = {
        "id": "",
        "isDeleted": false,
        "location": "",
        "userName": "",
        "firstName": "",
        "lastName": "",
        "region":'',
        "number": "",
        "profileImage": "",
        "userType": "",
        "status": false
    }

    const [employees, setEmployees] = useState(null);
    const [employeeDialog, setEmployeeDialog] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [deleteEmployeeDialog, setDeleteEmployeeDialog] = useState(false);
    const [resetPasswordForEmployeesDialog, setResetPasswordForEmployeesDialog] = useState(false);
    const [employee, setEmployee] = useState(emptyEmployee);
    const [selectedEmployees, setSelectedEmployees] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResetButton, setIsLoadingResetButton] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isUpdateEmployee, setIsUpdateEmployee] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [userTypes, setUserTypes] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [initialLoad, setInitialLoad] = useState(false);
    const employeesLoading = new Array(3);
    const imgURL = "localhost:5000";
    const history = useHistory();
    const companyService = new CompanyService();
    const employeeService = new EmployeeService();
    const [config, setConfig] = useState(null);
    const [regions, setRegions] = useState(null);
    const { userHasAuthenticated, userIsCompanyAdmin, isUserCompanyCashier, userIsCompanyCashier, isUserCompanySupervisor, userIsCompanySupervisor } = useAppContext();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setInitialLoad(true);

        //Get UserID 
        const user = JSON.parse(window.localStorage.getItem("user"));

        if (user == null || user.userType !== '3900') {
            history.push("/NotFound");
            return;
        }
        else {
            setLoggedInUser(user);

            let _config = { headers: { Authorization: `Bearer ${user.token}` } };
            setConfig({
                ..._config
            });

            employeeService.getAllCompanyUsers(_config).then(data => {
                setEmployees(data);
                setInitialLoad(false);
            }).catch(function (error) {
                console.log(error.response);

                if (error?.response?.status === 401) {
                    userHasAuthenticated(false);
                    //Clear Local storage
                    userIsCompanyAdmin(false);
                    userIsCompanyCashier(false);
                    userIsCompanySupervisor(false);
                    window.localStorage.clear();

                    //Redirect to login
                    history.push("/login");
                }
            });

            companyService.getAllCompanyRegions(_config).then(data => {
                setRegions(data);
                setInitialLoad(false);
            }).catch(function (error) {
                console.log(error.response);
                setInitialLoad(false);
    
                if(error?.response?.status === 401)
                {
                   userHasAuthenticated(false);
                   //Clear Local storage
                   userIsCompanyAdmin(false);
                   userIsCompanyCashier(false);
                   userIsCompanySupervisor(false);
                   window.localStorage.clear();
               
                   //Redirect to login
                    history.push("/login");
                } 
            });

            employeeService.getAllUserTypes(_config).then(data => setUserTypes(data));
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setEmployee(emptyEmployee);
        setSubmitted(false);
        setEmployeeDialog(true);
        setIsUpdateEmployee(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEmployeeDialog(false);
    }

    const hideDeleteEmployeeDialog = () => {
        setDeleteEmployeeDialog(false);
    }

    const hideResetPasswordForEmployeesDialog = () => {
        setResetPasswordForEmployeesDialog(false);
    }

    const confirmResetPasswordSelected = () => {
        setResetPasswordForEmployeesDialog(true);
    }
    const saveEmployee = () => {

        setSubmitted(true);
        setIsLoading(true);

        if (employee.firstName.trim()) {
            let _employees = [...employees];
            let _employee = { ...employee };

            if (employee.id) {
                //Existing user that need to be updated 
                const index = findIndexById(employee.id);
                _employees[index] = _employee;
                _employees[index].userType = employee.userType;
                _employees[index].regionId = employee.region.id;

                employeeService.updateCompanyUser(_employee, config).then(data => {
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Employee Added', life: 3000 });
                    setEmployees(_employees);
                    setEmployee(emptyEmployee);

                }).catch(function (error) {
                    setIsLoading(false);
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Failed to add employee', detail: error?.response?.data, life: 15000 });
                });
            }
            else {

                //could use a new drop down 
                
                //New user to be added 
                _employee.userType = employee.userType.name;
                _employee.regionId = employee.region.id;
                employeeService.postCompanyUser(_employee, config).then(data => {
                    setIsLoading(false);
                    _employees.push(data);
                    setEmployees(_employees);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Employee Added', life: 3000 });
                    setEmployee(emptyEmployee);

                }).catch(function (error) {
                    toast.current.show({ severity: 'error', summary: 'Failed to add employee', detail: error?.response?.data, life: 15000 });
                    setIsLoading(false);
                });
            }
        }

        setEmployeeDialog(false);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < employees.length; i++) {
            if (employees[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const editEmployee = (employee) => {
        let _regions = regions?.filter(val => val.id === employee.regionId);
        employee.region = _regions[0];
        // console.log("SELECTED" , employee);

        setIsUpdateEmployee(true);
        setEmployee({ ...employee });
        setEmployeeDialog(true);
    }

    const confirmDeleteEmployee = (employee) => {
        setEmployee(employee);
        setDeleteEmployeeDialog(true);
    }

    const deleteEmployee = () => {
        let _employees = [...employees];
        let _employee = { ...employee };
        _employee.isDeleted = true;

        const index = findIndexById(employee.id);
        _employees[index] = _employee;
        employeeService.updateCompanyUserStatus(_employee, config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Employee Deactivated on the system', life: 8000 });
            setEmployees(_employees);
            setEmployee(emptyEmployee);

        }).catch(function (error) {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed to Deactivate employee', detail: error?.response?.data, life: 3000 });
        });

        setDeleteEmployeeDialog(false);
    }

    const reActivateEmployee = (employeeToActivate) => {
        let _employees = [...employees];
        let _employee = { ...employeeToActivate };
        _employee.isDeleted = false;

        const index = findIndexById(employeeToActivate.id);
        _employees[index] = _employee;
        employeeService.updateCompanyUserStatus(_employee, config).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'info', summary: 'Successful', detail: 'Employee Re-Activated on the system', life: 8000 });
            setEmployees(_employees);
            setEmployee(emptyEmployee);
        }).catch(function (error) {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Failed to Re-Activate employee', detail: error.response.data, life: 3000 });
        });

        setDeleteEmployeeDialog(false);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const headerTabList = (
        <span >
            <i className="pi pi-list" style={{ marginRight: "10px" }} />
            <label style={{ cursor: "pointer" }}>Employee List </label>
        </span>
    );

    const headerTabSettings = (
        <span>
            <i className="pi pi-cog" style={{ marginRight: "10px" }} />
            <label>Employee Settings</label>
        </span>
    );

    const headerTabReports = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-clock" style={{ marginRight: "10px" }} />
            <label>Employee Time(Clocking System)</label>
        </span>
    );

    const headerTabTransactions = (
        <span style={{ cursor: "pointer" }}>
            <i className="pi pi-shopping-cart" style={{ marginRight: "10px" }} />
            <label>Employee Transactions</label>
        </span>
    );
    
    const resetPasswordForSelectedEmployee = () => {
        setResetPasswordForEmployeesDialog(false);
        setIsLoadingResetButton(true);
        employeeService.resetEmployeePassword(selectedEmployees[0], config).then(data => {
            setIsLoadingResetButton(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Password reset successful', life: 3000 });
        }).catch(function (error) {
            setIsLoadingResetButton(false);
            toast.current.show({ severity: 'error', summary: 'Failed to reset employee password', detail: error.response.data, life: 15000 });
        });

    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _employee = { ...employee };
        
        if(name !== "userType")
            _employee[`${name}`] = val;
        else
            _employee[`${name}`] = val.name;
        
        setEmployee(_employee);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Add New Employee" icon="pi pi-plus" className="p-button-success p-button-outlined p-mr-2" onClick={openNew} />
                { isLoadingResetButton
                    ? <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                    : <Button label="Reset user password" icon="pi pi-lock" className="p-button-warning p-button-outlined" onClick={confirmResetPasswordSelected} disabled={!selectedEmployees || !selectedEmployees.length || selectedEmployees.length !== 1} tooltip="Click to reset user password" />}

            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-outlined" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const addressBodyTemplate = (rowData) => {
        return <span >{rowData?.location == null ? "" : rowData?.location?.length > 35 ? rowData?.location?.substring(0, 35) + "..." : rowData?.location}</span>;
    }

    const regionBodyTemplate = (rowData) => {
        let _region = regions?.filter(val => val.id === rowData.regionId);
        
        return <span > {_region ? _region[0]?.name : ""}</span>;
    }

    const statusBodyTemplate = (rowData) => {
        // <span>{rowData.isDeleted ? <Tag severity="danger" value="Inactive"></Tag> : <Tag severity="success" value="Active "></Tag>}</span>;

        return  <span >{rowData.isDeleted ? 
            <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} tooltip={rowData.daysLeft + " days left"} label="Inactive" className="p-button-rounded p-button-outlined p-button-danger" /></Tag> 
            : <Tag style={{backgroundColor:'inherit'}}> <Button style={{fontSize:'inherit'}} label="Active" className="p-button-rounded p-button-outlined p-button-success" /></Tag>}</span>;
    }

    const bodyTemplate = () => {
        return <Skeleton></Skeleton>
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.isDeleted ?
                    <Button icon="pi pi-check-circle" tooltip="Re activate employee"  className="p-button-rounded p-button-success p-button-outlined" onClick={() => reActivateEmployee(rowData)} />
                    :
                    <div>
                        <Button icon="pi pi-pencil" tooltip="Edit employee" className="p-button-rounded p-button-warning p-button-outlined p-mr-2" onClick={() => editEmployee(rowData)} tooltip="edit user" />
                        {rowData.id !== loggedInUser.userID ? <Button icon="pi pi-ban" tooltip="Delete employee" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => confirmDeleteEmployee(rowData)} tooltip="delete user" /> : <Button icon="pi pi-ban" disabled className="p-button-rounded " tooltip="Reactivate user" />}
                    </div>
                }
            </React.Fragment>
        );
    }

    const imageBodyTemplate = (rowData) => {

        if (rowData.profileImage.includes("localhost"))
            return <img style={{ borderRadius: '50.5px' }} src={rowData.profileImage.replace('localhost', imgURL)} onError={(e) => e.target.src = rowData.profileImagereplace('localhost', imgURL)} alt={rowData.profileImage.replace('localhost', imgURL)} className="product-image" />
        else
            return <img style={{ borderRadius: '50.5px' }} src={rowData.profileImage} onError={(e) => e.target.src = rowData.profileImage} alt={rowData.profileImage} className="product-image" />
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Employees</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const employeeDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEmployee} />
        </React.Fragment>
    );
    const deleteEmployeeDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEmployeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEmployee} />
        </React.Fragment>
    );

    const resetPasswordForEmployeesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideResetPasswordForEmployeesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={resetPasswordForSelectedEmployee} />
        </React.Fragment>
    );


    return (
        <div className="datatable-crud-products">
            <Toast ref={toast} />
            <div className="card">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header={headerTabList}>
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        {!initialLoad
                            ?
                            <DataTable ref={dt} value={employees} selection={selectedEmployees} onSelectionChange={(e) => setSelectedEmployees(e.value)}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} employees"
                                globalFilter={globalFilter}
                                header={header}>

                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="firstName" header="First Name" sortable></Column>
                                <Column field="lastName" header="Last Name" sortable></Column>
                                <Column field="userName" header="Email" sortable></Column>
                                {/* <Column header="Photo" body={imageBodyTemplate}></Column> */}
                                <Column field="number" header="Cell Number" sortable></Column>
                                <Column field="userType" header="Employee Type" sortable></Column>
                                <Column field="location" header="Address" body={addressBodyTemplate} ></Column>
                                <Column field="region" header="Region" body={regionBodyTemplate} sortable ></Column>
                                <Column field="isDeleted" header="Status" body={statusBodyTemplate} sortable></Column>
                                <Column header="Action" body={actionBodyTemplate}></Column>
                            </DataTable>

                            :
                            <DataTable value={employeesLoading} className="p-datatable-striped">
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="firstName" header="First Name" body={bodyTemplate} sortable></Column>
                                <Column field="lastName" header="Last Name" body={bodyTemplate} sortable></Column>
                                <Column field="userName" header="Email" body={bodyTemplate} sortable></Column>
                                {/* <Column header="Photo" body={bodyTemplate}></Column> */}
                                <Column field="number" header="Cell Number" body={bodyTemplate} sortable></Column>
                                <Column field="userType" header="Employee Type" body={bodyTemplate} sortable></Column>
                                <Column field="location" header="Address" body={bodyTemplate} ></Column>
                                <Column field="region" header="Region" body={bodyTemplate} ></Column>
                                <Column field="isDeleted" header="Status" body={bodyTemplate} sortable></Column>
                                <Column header="Action" body={bodyTemplate}></Column>
                            </DataTable>
                        }
                        {isLoading ? <ProgressSpinner /> : ""}
                    </TabPanel>

                    <TabPanel header={headerTabSettings}> {/* Transaction Settings */}
                        <h1>Add stuff</h1>
                    </TabPanel>

                    <TabPanel header={headerTabReports}> {/* Transaction Reports*/}
                         <h1>Add things here...</h1>

                    </TabPanel>

                    <TabPanel header={headerTabTransactions}> {/* Transaction Reports*/}
                         <TransactionComponent/>
                    </TabPanel>

                </TabView>
            </div>

            <Dialog visible={employeeDialog} style={{ width: '450px', textAlign: 'left' }} header="Employee Details" modal className="p-fluid" footer={employeeDialogFooter} onHide={hideDialog}>

                <div className="p-field">
                    <label htmlFor="userType">User Type</label>
                    <Dropdown id="userType" value={employee?.userType} options={userTypes} onChange={(e) => onInputChange(e, 'userType')} /*onChange={onCityChange}*/ optionLabel="name" placeholder={employee.userType ? employee.userType : "Select User Type"} required autoFocus />
                </div>

                <div className="p-field">
                    <label htmlFor="region" >Region</label>
                    <Dropdown id="region" value={employee?.region} options={regions} onChange={(e) => onInputChange(e, 'region')} optionLabel="name" placeholder={employee.region ? employee.region.name : 'Select Region'} required className={classNames({ 'p-invalid': submitted && !employee.region })} />
                </div>

                <div className="p-field">
                    <label htmlFor="firstName">First Name</label>
                    <InputText id="firstName" value={employee.firstName} onChange={(e) => onInputChange(e, 'firstName')} required className={classNames({ 'p-invalid': submitted && !employee.firstName })} />
                    {submitted && !employee.firstName && <small className="p-error">Fisrt Name is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="lastName">Last Name</label>
                    <InputText id="lastName" value={employee.lastName} onChange={(e) => onInputChange(e, 'lastName')} required className={classNames({ 'p-invalid': submitted && !employee.lastName })} />
                    {submitted && !employee.lastName && <small className="p-error">Last Name is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="userName">Email</label>
                    <InputText id="userName" type="userName" disabled={employee.status} value={employee.userName} onChange={(e) => onInputChange(e, 'userName')} required className={classNames({ 'p-invalid': submitted && !employee.userName })} />
                    {submitted && !employee.userName && <small className="p-error">Email is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="number">Cell Number</label>
                    <InputText id="number" value={employee.number} disabled={employee.status} onChange={(e) => onInputChange(e, 'number')} required className={classNames({ 'p-invalid': submitted && !employee.number })} />
                    {submitted && !employee.number && <small className="p-error">Cell Number is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="location">Address</label>
                    <InputText id="location" value={employee.location} onChange={(e) => onInputChange(e, 'location')} required className={classNames({ 'p-invalid': submitted && !employee.location })} />
                    {submitted && !employee.location && <small className="p-error">Address is required.</small>}
                </div>

            </Dialog>

            <Dialog visible={deleteEmployeeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEmployeeDialogFooter} onHide={hideDeleteEmployeeDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {employee && <span>Are you sure you want to Deactivate <b>{employee.firstName}'s account</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={resetPasswordForEmployeesDialog} style={{ width: '450px' }} header="Confirm" modal footer={resetPasswordForEmployeesDialogFooter} onHide={hideResetPasswordForEmployeesDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {employee && <span>Are you sure you want to reset the {selectedEmployees ? selectedEmployees[0]?.firstName : ""}'s password ?</span>}
                </div>
            </Dialog>
        </div>
    );
}
export default Employees