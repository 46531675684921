import axios from 'axios';
import API_URL from './ApiUrl';
const apiBaseUrl = API_URL

export default class CompanyService {
    async getAllCompanyUsers(config) {
        return axios.get(`${apiBaseUrl}Users/GetAllCompanyUsers`,config).then(res => res.data);
    }

    async getAllCompanyRegions(config) {
        return axios.get(`${apiBaseUrl}Company/GetAllCompanyRegions`,config).then(res => res.data);
    }

    async postCompanyUser(payload,config){
        return await axios.post(`${apiBaseUrl}Users/AddCompanyUser`,payload,config).then(res => res.data);
    }

    async addCompanyRegion(payload,config){
        return await axios.post(`${apiBaseUrl}Company/AddCompanyRegion`,payload,config).then(res => res.data);
    }

    async updateCompanyRegion(payload,config){
        return await axios.put(`${apiBaseUrl}Company/UpdateCompanyRegion`,payload,config).then(res => res.data);
    }
}