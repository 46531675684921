import axios from 'axios';
import API_URL from './ApiUrl';
const apiBaseUrl = API_URL

export default class ProductService {

     getProducts() {
        return  axios.get('./data/products.json').then(res => res.data.data);
    }

    async getAllProducts(config) {
        return await axios.get(`${apiBaseUrl}Products/GetAllCompanyProducts`,config).then(res => res.data);
    }
    
    async getAllCashierProducts(config) {
        return await axios.get(`${apiBaseUrl}Products/GetAllProductsForCashier`,config).then(res => res.data);
    }
    

    async getSuggestedBrands() {
        return await axios.get('data/brands.json').then(res => res.data.data);
    }

    async getSuggestedCategories() {
        return await axios.get('data/categories.json').then(res => res.data.data);
    }

    async postCategories(payload,config){
        return await axios.post(`${apiBaseUrl}Products/PostProductCategories`,payload,config).then(res => res.data);
    }

    async postBrands(payload,config){
        return await axios.post(`${apiBaseUrl}Products/PostProductBrands`,payload,config).then(res => res.data);
    }

    async postProduct(payload,config){
        return await axios.post(`${apiBaseUrl}Products/AddProduct`,payload,config).then(res => res.data);
    }

    async updateExistingScannedProduct(payload,config){
        return await axios.put(`${apiBaseUrl}Products/AddExistingProduct`,payload,config).then(res => res.data);
    }
    
    async updateProduct(payload,config){
        return await axios.put(`${apiBaseUrl}Products/UpdateProduct`,payload,config).then(res => res.data);
    }

    async deleteProduct(payload,config){
        return await axios.put(`${apiBaseUrl}Products/DeleteProduct`,payload,config).then(res => res.data);
    }

    async postProductSettings(payload,config){
        return await axios.post(`${apiBaseUrl}Products/PostProductSettings`,payload,config).then(res => res.data);
    }

    async getAllBrands(config) {
        return await axios.get(`${apiBaseUrl}Products/GetAllBrands`,config).then(res => res.data);
    }

    async getAllCategories(config) {
        return await axios.get(`${apiBaseUrl}Products/GetAllCategories`,config).then(res => res.data);
    }

    async getProductQuatityLevel(config) {
        return await axios.get(`${apiBaseUrl}Products/GetProductQuatityLevel`,config).then(res => res.data);
    }
}
    