/* eslint-disable no-undef */
let API_URL = 'https://api.nogosoft.co.za';
// let HOST_URL = 'https://nogosoft.co.za/';

let HOST_URL = 'http://localhost:3000';
// let API_URL = 'https://localhost:5001'; // local



if(process.env.NODE_ENV == 'development') {
	HOST_URL = HOST_URL;
	API_URL = `${API_URL}/api/`;//Change this to QA one
} else {
	HOST_URL = HOST_URL;
	API_URL = `${API_URL}/api/`;//Change this to QA one
}

export default API_URL;
export {HOST_URL};
