import axios from 'axios';
import API_URL from './ApiUrl';
const apiBaseUrl = API_URL

export default class SupplierService {

    formatCurrency = (value) => {
        return value.toLocaleString('en-za', { style: 'currency', currency: 'ZAR' });
    }
    
    async getAllSuppliers(config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetAllSupplier`,config).then(res => res.data);
    }

    async getAllSupplierProducts(supplierId,config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetAllSupplierProducts?supplierId=${supplierId}`,config).then(res => res.data);
    }

    async getAllSupplierSuggestedProducts(supplierId,config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetAllSupplierSuggestedProducts?supplierId=${supplierId}`,config).then(res => res.data);
    }

    async getSupplierById(supplierId,config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetSupplierById?supplierId=${supplierId}`,config).then(res => res.data);
    }

    async getAllSupplierInvoices(year,supplierId, config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetAllSupplierInvoices?year=${year}&supplierId=${supplierId}`,config).then(res => res.data);
    }
    
    async getSupplierInvoiceSetting(supplierId, config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetSupplierInvoiceSetting?supplierId=${supplierId}`,config).then(res => res.data);
    }
    
    async getAllPeriods(supplierId, config) {
        return await axios.get(`${apiBaseUrl}Suppliers/GetAllPeriods?supplierId=${supplierId}`,config).then(res => res.data);
    }

    async postSupplier(payload,config){
        return await axios.post(`${apiBaseUrl}Suppliers/PostSupplier`,payload,config).then(res => res.data);
    }

    async postSupplierInvoice(payload,supplierId,config){
        return await axios.post(`${apiBaseUrl}Suppliers/PostSupplierInvoice?supplierId=${supplierId}`,payload,config).then(res => res.data);
    }

    async postSupplierInvoiceSettings(payload,supplierId,config){
        return await axios.post(`${apiBaseUrl}Suppliers/PostSupplierInvoiceSetting?supplierId=${supplierId}`,payload,config).then(res => res.data);
    }
    
    async updateSupplier(payload,config){
        return await axios.put(`${apiBaseUrl}Suppliers/UpdateSupplier`,payload,config).then(res => res.data);
    }

    //Orders 
    async PostSupplierOrder(payload,supplierId,config){
        return await axios.post(`${apiBaseUrl}Suppliers/postSupplierOrder?supplierId=${supplierId}`,payload,config).then(res => res.data);
    }
    
}