import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavDropdown, NavItem,MenuItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { Tag } from 'primereact/tag';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab} from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Logo from 'Logo/Nogosoft.png'
import Logo from './Logo/Nogosoft.png'
const NavBarTextColor = "white" ;
const NavBarBackgroundColor = "#050A30" ;

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isUserCompanyAdmin, userIsCompanyAdmin] = useState(false);
  const [isUserCompanyCashier, userIsCompanyCashier] = useState(false);
  const [isUserCompanySupervisor, userIsCompanySupervisor] = useState(false);
  const [config, setConfig] = useState(null);
  const history = useHistory();

  useEffect(() => {
    onLoad(); //TODO: Remove this 
    // history.push("/login");
  }, []);

  async function onLoad() {
    try {
      //Check if they LoggedIn
      var status = window.localStorage.getItem("user") !== null ? true : false;
      userHasAuthenticated(status);

      if (status) {
        var user = JSON.parse(localStorage.getItem("user"));

        if (user.userType === '3900') {
          userIsCompanyAdmin(true);
          userIsCompanyCashier(false);
          userIsCompanySupervisor(false);
        }
        else if (user.userType === '720') {
          userIsCompanyAdmin(false);
          userIsCompanyCashier(true);
          userIsCompanySupervisor(false);
          history.push("/Transact");
        }
        else if (user.userType === '330') {
          userIsCompanyAdmin(false);
          userIsCompanyCashier(false);
          userIsCompanySupervisor(true);
        }
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  function handleLogout() {
    userHasAuthenticated(false);
    //Clear Local storage
    userIsCompanyAdmin(false);
    userIsCompanyCashier(false);
    userIsCompanySupervisor(false);
    window.localStorage.clear();

    //Redirect to home 
    history.push("/");
  }

  const title =(
    <React.Fragment>
      <i className="pi pi-bars p-mr-2"></i>
    </React.Fragment>
    );

  return (
    <div className="App">
      <Navbar fluid collapseOnSelect style={{ backgroundColor: NavBarBackgroundColor}}>
        <Navbar.Header>
          <Navbar.Brand>
      

            <Link to="/" style={{ color: NavBarTextColor}}> <img src={Logo} alt="..." style={{height:'30px'}}/>{isAuthenticated && isUserCompanyAdmin ? <Tag value="Admin" icon="pi pi-user"></Tag> : ""}  {isUserCompanyCashier && <Tag value="Cashier" icon="pi pi-user"></Tag>} {isUserCompanySupervisor && <Tag value="Supervisor" icon="pi pi-user"></Tag>}</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>

            {isAuthenticated && isUserCompanyAdmin
              ? <>
                
                <LinkContainer to="/Employees">
                  <NavItem>Employees</NavItem>
                </LinkContainer>

                <LinkContainer to="/Expenses">
                  <NavItem>Expenses</NavItem>
                </LinkContainer>

                <LinkContainer to="/testPage">
                  <NavItem>Customers</NavItem>
                </LinkContainer>

                <LinkContainer to="/Suppliers">
                  <NavItem>Suppliers</NavItem>
                </LinkContainer>

                <LinkContainer to="/Products">
                  <NavItem>Products</NavItem>
                </LinkContainer>

                <LinkContainer to="/Transactions">
                  <NavItem>Transactions</NavItem>
                </LinkContainer>

                {/* <LinkContainer to="/Settings">
                  <NavItem>Settings</NavItem>

                </LinkContainer> */}

                <NavDropdown eventKey={1} title={title} id="Settings">
                <LinkContainer to="/Regions">
                      <NavItem><FontAwesomeIcon icon="sitemap" /> Regions</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/Settings">
                      <NavItem><FontAwesomeIcon icon="cogs" /> Settings</NavItem>
                      {/* 1. Set what employees can see
                          2. Set if all products are inclusive of VAT  */}
                  </LinkContainer>
                  <LinkContainer to="/Tax">
                      <NavItem><FontAwesomeIcon icon="cogs" /> Tax</NavItem>
                      {/* 1. VAT Input 
                          2. VAT Output 
                          3. VAT balance due/refundable */}

                  </LinkContainer>

                  <MenuItem><FontAwesomeIcon icon="user-cog" /> Edit Profile</MenuItem>
                  {/* 1. Profile Picture 
                      2.  */}

                  <MenuItem><FontAwesomeIcon icon="bell" /> Notification Preferences</MenuItem> 
                  {/* 1. Notify me when there's new update on supplier order
                      2. When stock hits low stock quantity 
                      3. Notify when an invoice is due to pay suppliers  */}

                  <MenuItem eventKey={1.1}> <FontAwesomeIcon icon="lock" /> Change Password</MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={1.2} onClick={handleLogout}> <FontAwesomeIcon icon="sign-out-alt" /> Logout</MenuItem>
                </NavDropdown>
              </>
              :
              <>
                {!isAuthenticated && <LinkContainer to="/login">
                  <NavItem>Login</NavItem>
                </LinkContainer>}
              </>
            }

            {!isAuthenticated && !isUserCompanyAdmin ? <></> : isAuthenticated && !isUserCompanyAdmin ? <NavItem onClick={handleLogout}>Logout</NavItem> : <></>}<></>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isUserCompanyAdmin, userIsCompanyAdmin, isUserCompanyCashier, userIsCompanyCashier, isUserCompanySupervisor, userIsCompanySupervisor, config, setConfig }}>
        <Routes />
      </AppContext.Provider>


    </div>
  );
}
library.add(fab,fas)

export default App;
