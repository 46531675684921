import "./Products.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { AutoComplete } from 'primereact/autocomplete';
import ProductService from '../Service/ProductService';
import CompanyService from '../Service/CompanyService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chips } from 'primereact/chips';
import { Toolbar } from 'primereact/toolbar';

const CompanySettings = () => {
    const toast = useRef(null);

    //3 fields for categories 
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [newCategory, setNewCategory] = useState('');
    const [lowSock, setLowStock] = useState(1);
    const productService = new ProductService();
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState(null);
    const [filteredBrands, setFilteredBrands] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState(null);

    //Region

    useEffect(() => {

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function saveChanges() {

        console.log("Changes saved Successfully...")
    }

    const customChip = (item) =>{
        return (
            <div>
                <span>{item} - (0) </span>
                <i className="pi pi-users"></i>
            </div>
        );
    }
    
    return (

        <div className="">
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 toolbarSetting" left={<b>Company Regions</b>}></Toolbar>
            <div className="card p-grid" style={{ backgroundColor: "white" }}>
                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                    <h5 align="left">Regions</h5>
                    {/* <Chips name="regions" value={regions} onChange={(e) => setRegions(e.value)} placeholder="Enter region here"></Chips> */}
                    <Chips value={regions} onChange={(e) => setRegions(e.value)} itemTemplate={customChip}></Chips>
                    <small id="regions-help">Press enter after each region entered.</small>

                </div>
                
            </div>

            {/* Products Brand */}
            {/* <Toolbar className="p-mb-4 toolbarSetting" left={<b>Products Brand</b>} ></Toolbar>
            <div className="card p-grid" style={{ backgroundColor: "white" }}>

                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                    <h5 align="left">Search the type of brands your company sell</h5>
                    <AutoComplete placeholder={selectedBrands != null ? "" : "e.g Madza or Coca Cola"} value={selectedBrands} suggestions={filteredBrands} completeMethod={searchBrand} field="name" multiple onChange={(e) => setSelectedBrands(e.value)} />
                    <small id="brands-help">You can add your own brand below.</small>

                    <div className="p-buttonset" style={{ marginLeft: "-7px", marginTop: '10px', }}>
                        <div className=" p-col-4">
                            <InputText id="addBrand" placeholder="Add new brand" aria-describedby="addBrand-help" value={newBrand} onChange={(e) => setNewBrand(e.target.value)} className="p-d-block" />
                        </div>
                        <div className=" p-col-2">
                            <Button label="Add" className="p-button-rounded p-button-outlined p-button-info" onClick={() => addBrand()} />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Products Categories */}
            <Toolbar className="p-mb-4 toolbarSetting" left={<b>Products Categories</b>}></Toolbar>
            <div className="card p-grid" style={{ backgroundColor: "white" }}>

                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                    <div className="p-buttonset p-justify-end" style={{ marginLeft: "-7px", marginTop: '10px' }}>
                        <div className=" p-col-2">
                            {isLoading ? <ProgressSpinner /> : <Button label="Save Changes" disabled={(regions === null)} className="p-button-rounded  p-button-success" onClick={() => saveChanges()} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CompanySettings