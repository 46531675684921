import axios from 'axios';
import API_URL from './ApiUrl';
const apiBaseUrl = API_URL

export default class UserService {
    async getAllCompanyUsers(config) {
        return axios.get(`${apiBaseUrl}Users/GetAllCompanyUsers`,config).then(res => res.data);
    }

    async getAllEmployees(config) {
        return axios.get(`${apiBaseUrl}Users/GetAllEmployees`,config).then(res => res.data);
    }
    
    async getAllUserTypes(config) {
        return axios.get(`${apiBaseUrl}Users/GetAllUserTypes`,config).then(res => res.data);
    }

    async postCompanyUser(payload,config){
        return await axios.post(`${apiBaseUrl}Users/AddCompanyUser`,payload,config).then(res => res.data);
    }

    async updateCompanyUser(payload,config){
        return await axios.put(`${apiBaseUrl}Users/UpdateCompanyUser`,payload,config).then(res => res.data);
    }
    async updateCompanyUserStatus(payload,config){
        return await axios.put(`${apiBaseUrl}Users/UpdateCompanyUserStatus`,payload,config).then(res => res.data);
    }
    
    async resetEmployeePassword(payload,config){
        return await axios.put(`${apiBaseUrl}Users/ResetCompanyUserPassword`,payload,config).then(res => res.data);
    }
}