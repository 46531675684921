import "./Products.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { AutoComplete } from 'primereact/autocomplete';
import ProductService from '../Service/ProductService';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';

const TestPage = () => {
    const toast = useRef(null);

    //3 fields for categories 
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [newCategory, setNewCategory] = useState('');
    const [lowSock, setLowStock] = useState(1);
    const productService = new ProductService();
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState(null);
    const [filteredBrands, setFilteredBrands] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [newBrand, setNewBrand] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        
        const user =  JSON.parse(window.localStorage.getItem("user")); 

        if(user == null)
            return;
            
        setLoggedInUser(user);

        //Suggested Brands and categories 
        productService.getSuggestedCategories().then(data => {
            let _categories = [];
            _categories.push(...data);
            
            //Brands categories saved 
            productService.getAllCategories(user.userID).then(dataOther => 
            {
                _categories.push(...dataOther)
                setCategories(_categories);
                setSelectedCategories(dataOther);
            });         
            
        });
        
        //Get Brands
        productService.getSuggestedBrands().then(data => {
            let _brands = [];
            _brands.push(...data);
        
            productService.getAllBrands(user.userID).then(dataOther => {
                _brands.push(...dataOther);
                setBrands(_brands);
                setSelectedBrands(dataOther);
            });
        });

        //Get quantityLevel
        productService.getProductQuatityLevel(user.userID).then(data => setLowStock(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const searchCategory = (event) => {
        setTimeout(() => {
            let filteredCategories;
            if (!event.query.trim().length) {
                filteredCategories = [...categories];
            }
            else {
                filteredCategories = categories.filter((category) => {
                    return category.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCategories(filteredCategories);
        }, 10);
    }

    const searchBrand = (event) => {
        setTimeout(() => {
            let filteredBrands;
            if (!event.query.trim().length) {
                filteredBrands = [...brands];
            }
            else {
                filteredBrands = brands.filter((brand) => {
                    return brand.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredBrands(filteredBrands);
        }, 10);
    }

    const addBrand = () => {
        if (newBrand) {
            let _brands = [...brands];
            let _brand = {
                name: newBrand,
                id:1
            }

            _brands.push(_brand);
            setBrands(_brands);

            if (selectedBrands) {
                let _selectedBrands = [...selectedBrands];

                _selectedBrands.push(_brand);

                setSelectedBrands(_selectedBrands);
            }
            else {
                setSelectedBrands([_brand]);
            }

            setNewBrand('');
        }
    }

    const addCategory = () => {
        if (newCategory) {
            let _categories = [...categories];
            let _category = {
                name: newCategory,
                id:2
            }

            _categories.push(_category);
            setCategories(_categories);

            if (selectedCategories) {
                let _selectedCategories = [...selectedCategories];

                _selectedCategories.push(_category);

                setSelectedCategories(_selectedCategories);
            }
            else {
                setSelectedCategories([_category]);
            }

            setNewCategory('');
        }
    }

    async function saveChanges(){
        setIsLoading(true);

        let payload = {
            brands : selectedBrands,
            categories : selectedCategories,
            quantityLevel : lowSock
        }
        await productService.postProductSettings(payload,loggedInUser.userID).then(data => {
            setIsLoading(false);
            toast.current.show({ severity: 'success', summary: 'Success', detail: "Product settings saved", life: 3000 });

        }).catch(function (error) {      
            setIsLoading(false);
            console.log(error.response)
            toast.current.show({ severity: 'error', summary: 'Failed', detail: "error", life: 7000 });
          });
        
    }
    
    return (
        <div>
        <Toast ref={toast} />
            {/* <Toolbar className="p-mb-4" left={"LEFT"}></Toolbar> */}
            <div className="card p-grid" style={{ backgroundColor: "white" }}>
                <Divider align="left"><span className="p-tag">Stock Levels</span></Divider>

                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                <h5 align="left">Product is low in stock when quantity on hand is :</h5>
                    <div className="p-buttonset" style={{ marginLeft: "-7px", marginTop: '10px' }}>
                        <div className=" p-col-4">
                           <InputNumber value={lowSock} onValueChange={(e) => setLowStock(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card p-grid" style={{ backgroundColor: "white" }}>
                <Divider align="left"><span className="p-tag">Products Brand</span></Divider>

                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                    <h5 align="left">Search the type of brands your company sell</h5>
                    <AutoComplete placeholder={selectedBrands != null ? "" : "e.g Madza or Coca Cola"} value={selectedBrands} suggestions={filteredBrands} completeMethod={searchBrand} field="name" multiple onChange={(e) => setSelectedBrands(e.value)} />
                    <small id="brands-help">You can add your own brand below.</small>

                    <div className="p-buttonset" style={{ marginLeft: "-7px", marginTop: '10px', }}>
                        <div className=" p-col-4">
                            <InputText id="addBrand" placeholder="Add new brand" aria-describedby="addBrand-help" value={newBrand} onChange={(e) => setNewBrand(e.target.value)} className="p-d-block" />
                        </div>
                        <div className=" p-col-2">
                            <Button label="Add" className="p-button-rounded p-button-info" onClick={() => addBrand()} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card p-grid" style={{ backgroundColor: "white" }}>
                <Divider align="left"><span className="p-tag">Products Categories</span></Divider>

                <div className="p-col-6 p-fluid" style={{ marginLeft: "15px", marginTop: '-25px', textAlign: 'left' }}>
                    <h5 align="left">Search all categories that apply to your products</h5>
                    <AutoComplete value={selectedCategories} suggestions={filteredCategories} completeMethod={searchCategory} field="name" multiple onChange={(e) => setSelectedCategories(e.value)} />
                    <small id="categories-help">You can add your own category below.</small>

                    <div className="p-buttonset" style={{ marginLeft: "-7px", marginTop: '10px', }}>
                        <div className=" p-col-4">
                            <InputText id="addCategory" placeholder="Add new category" aria-describedby="addCat-help" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} className="p-d-block" />
                        </div>
                        <div className=" p-col-2">
                            <Button label="AddCat" className="p-button-rounded p-button-info" onClick={() => addCategory()} />
                        </div>
                    </div>
                    <div className="p-buttonset p-justify-end" style={{ marginLeft: "-7px", marginTop: '10px'}}>
                        <div className=" p-col-2">
                        {isLoading ? <ProgressSpinner /> : <Button label="Save Changes" disabled={ (lowSock === null || selectedCategories === null || selectedBrands === null)} className="p-button-rounded p-button-success" onClick={() => saveChanges()} /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TestPage