import axios from 'axios';
import API_URL from './ApiUrl';
const apiBaseUrl = API_URL


export default class TransactionService {
    
    formatCurrency = (value) => {
        return value.toLocaleString('en-za', { style: 'currency', currency: 'ZAR' });
    }

    async getAllCompanyTransactions(config) {
        return await axios.get(`${apiBaseUrl}Transaction/GetAllCompanyTransactions`,config).then(res => res.data);
    }

    async getAllPeriods(config) {
        return await axios.get(`${apiBaseUrl}Transaction/GetAllPeriods`,config).then(res => res.data);
    }

    async getTransactionByID(config, transactionId) {
        return await axios.get(`${apiBaseUrl}Transaction/GetTransactionByID?transactionId=${transactionId}`,config).then(res => res.data);
    }

    async getTreeTableTransactions(config,year) {
        return await axios.get(`${apiBaseUrl}Transaction/GetTreeTransactionsByYear?year=${year}`,config).then(res => res.data);
    }

    async getTreeTransactionsByUserCode(config,year,code) {
        return await axios.get(`${apiBaseUrl}Transaction/GetTreeTransactionsByUserCode?year=${year}&code=${code}`,config).then(res => res.data);
    }

    async getTreeTransactionsByProductCode(config,year,productCode) {
        return await axios.get(`${apiBaseUrl}Transaction/GetTreeTransactionsByProductCode?year=${year}&productCode=${productCode}`,config).then(res => res.data);
    }
    async postTransaction(payload,config){
        return await axios.post(`${apiBaseUrl}Transaction/PostTransaction`,payload,config).then(res => res.data);
    }
}
    