import React from "react";
import "./Home.css";
import "primereact/resources/themes/md-light-indigo/theme.css"

export default function Home() {


  // function accessAPI(){
    
  //   var url = apiBaseUrl + `Information/GetInfromation`;
  //   const user =  JSON.parse(window.localStorage.getItem("user"));
  //   axios.defaults.headers.common = {'Authorization': `Bearer ${user.token}`}

  //   axios.get(url)
  //        .then(function (response) {
            
  //         alert(response.data);
        
  //       }).catch(function (error) {      
  //         console.log(error)
  //         alert(error.title);
  //       });
  // }
  return (
    <div className="Home">
      <div className="lander">
                <h2 >Inventory Management Software System</h2>
                <div className="form-group">
                    <p>Our inventory management software allows users to efficiently track the exact location and stock levels of your products in real time.</p>

                    {/* <button onClick = {()=>accessAPI()}>Click here</button> */}
            </div>
      </div>
    </div>
  );
}